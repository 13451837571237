import React from 'react';

const OkIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99967 0.833008C2.69967 0.833008 0.833008 2.69967 0.833008 4.99967C0.833008 7.29967 2.69967 9.16634 4.99967 9.16634C7.29967 9.16634 9.16634 7.29967 9.16634 4.99967C9.16634 2.69967 7.29967 0.833008 4.99967 0.833008ZM4.16634 7.08301L2.08301 4.99967L2.67051 4.41217L4.16634 5.90384L7.32884 2.74134L7.91634 3.33301L4.16634 7.08301Z"
        fill="#2485EA"
      />
    </svg>
  );
};

export default OkIcon;
