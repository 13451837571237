import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormControlLabel,
  FormGroup,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core';
import { ButtonEnterprise, InputCurrency } from 'components';
import { useEnterpriseContext } from 'contexts/Enterprises';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { findAddressByCEP } from 'services/viacep';
import { enterpriseSchema } from 'validations/enterprise.schema';

type Props = {
  onFormSubmit(params: any): Promise<void>;
  defaultValues: any;
  buttonTitle: string;
};

export const EnterpriseForm: React.FC<Props> = ({
  defaultValues,
  onFormSubmit,
  buttonTitle,
}) => {
  const [shouldRenderExternalId, setShouldRenderExternalId] = useState(true);

  const { portals, zones } = useEnterpriseContext();
  const { register, handleSubmit, watch, setValue, setFocus, control } =
    useForm({
      resolver: yupResolver(enterpriseSchema),
      defaultValues,
    });

  const onSubmit = async (data: any) => {
    if (data.portal !== 'Apto.vc' && !data.enterpriseId) {
      setFocus('enterpriseId');
      return;
    }

    onFormSubmit(data);
  };

  useEffect(() => {
    async function handleFindAddressByCEP() {
      if (watch('cep')?.length === 8) {
        const { data } = await findAddressByCEP(watch('cep'));
        setValue('address', data.logradouro);
        setValue('district', data.bairro);
        setValue('city', data.localidade);
      }
    }

    handleFindAddressByCEP();
  }, [watch('cep')]);

  useEffect(() => {
    if (watch('portal') === 'Apto.vc') {
      setShouldRenderExternalId(false);
      return;
    }

    if (shouldRenderExternalId) {
      return;
    }

    setShouldRenderExternalId(true);
  }, [watch('portal')]);

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormGroup
        row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <FormControlLabel
          control={
            <Switch
              {...register('isPartnerDeveloper')}
              checked={watch('isPartnerDeveloper')}
            />
          }
          label="Empreedimento Parceiro"
        />
        <FormControlLabel
          control={
            <Switch
              defaultChecked
              {...register('isActive')}
              checked={watch('isActive')}
            />
          }
          label="Empreendimento Ativo"
          {...register('isActive')}
        />
      </FormGroup>
      <TextField
        size="small"
        variant="outlined"
        margin="normal"
        label="Nome do Empreendimento"
        {...register('name')}
      />
      <TextField
        select
        size="small"
        variant="outlined"
        margin="normal"
        label="Portal"
        value={watch('portal')}
        {...register('portal')}
      >
        {portals.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </TextField>
      {shouldRenderExternalId && (
        <TextField
          size="small"
          variant="outlined"
          margin="normal"
          label="Empreendimento id (external id)"
          {...register('enterpriseId')}
        />
      )}

      <TextField
        select
        size="small"
        variant="outlined"
        margin="normal"
        label="Zona"
        value={watch('zone')}
        {...register('zone')}
      >
        {zones.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </TextField>

      <Controller
        name="price"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            onChange={onChange}
            value={value}
            size="small"
            variant="outlined"
            margin="normal"
            label="Preço"
            InputProps={{
              inputComponent: InputCurrency,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />

      <TextField
        size="small"
        variant="outlined"
        margin="normal"
        label="Link"
        {...register('link')}
      />
      <TextField
        size="small"
        variant="outlined"
        margin="normal"
        label="Link das Fotos"
        {...register('photoLink')}
      />
      <TextField
        size="small"
        variant="outlined"
        margin="normal"
        label="CEP"
        InputLabelProps={{
          shrink: true,
        }}
        {...register('cep')}
      />
      <TextField
        size="small"
        variant="outlined"
        margin="normal"
        label="Cidade"
        InputLabelProps={{
          shrink: true,
        }}
        {...register('city')}
      />
      <FormGroup row>
        <TextField
          size="small"
          variant="outlined"
          margin="normal"
          label="Endereço"
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            flex: 1,
          }}
          {...register('address')}
        />
        <TextField
          size="small"
          variant="outlined"
          margin="normal"
          label="Número"
          style={{
            width: 100,
            marginLeft: 10,
          }}
          {...register('addressNumber')}
        />
      </FormGroup>

      <TextField
        size="small"
        variant="outlined"
        margin="normal"
        label="Bairro"
        InputLabelProps={{
          shrink: true,
        }}
        {...register('district')}
      />
      <ButtonEnterprise style={{}} type="submit">
        {buttonTitle}
      </ButtonEnterprise>
    </form>
  );
};
