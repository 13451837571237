/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, ConfirmModal, PageTitle, Select } from 'components';
import Switch from '@material-ui/core/Switch';
import { Toaster } from 'react-hot-toast';

import { useQueryString } from 'hooks';
import { debounce } from 'lodash';
import { MenuItem, TextField } from '@material-ui/core';
import {
  enableDisableXMLRelatory,
  findActiveXMLRelatory,
  findAllXMLRelatorys,
  IXMLRelatory,
} from 'services/xmlRelatory';
import Pagination from '@material-ui/lab/Pagination';
import { toLocateDate } from 'utils/format';
import Container from './styles';
import { ComparativeCard } from './comparativeCard';
import { CardContent } from './cardContent';

const EnterprisesZapXmlPage = () => {
  const [realtorList, setRealtorList] = useState([]);
  const [XMLRelatorys, setXMLRelatorys] = useState<IXMLRelatory[]>([]);
  const [totalXMLRelatorys, setTotalXMLRelatorys] = useState(0);
  const [leads, setLeads] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(1);
  const [myFilters, setMyFilters] = useState({
    page: String(currentPage),
    limit: String(10),
  });
  const filters = useQueryString(myFilters);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateRelatorys, setUpdateRelatorys] = useState<{
    activeRelatory: IXMLRelatory;
    newRelatory: IXMLRelatory;
  }>({} as any);
  const [confirmedRelatory, setConfirmerdRelatory] =
    useState<IXMLRelatory | null>(null);

  const onInputChange = (value: string, key: string) => {
    setMyFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const toggleConfirmModal = () => setIsModalOpen((prevState) => !prevState);

  const confirmActivateXML = async () => {
    toggleConfirmModal();
  };

  const debouncedInputChangeHandler = useMemo(
    () => debounce(onInputChange, 300),
    [],
  );

  const quantityItems = count >= 7 ? 7 : count;

  const handleSortBy = (array: any[], property: string) => {
    return array.sort((a, b) => {
      if (a[property] < b[property]) {
        return -1;
      }
      if (a[property] > b[property]) {
        return 1;
      }
      return 0;
    });
  };

  const handleFindAllXMLRelatorys = async (qs?: string) => {
    const { data } = await findAllXMLRelatorys(qs);
    setXMLRelatorys(data.xmlRelatorys);
    setTotalXMLRelatorys(data.total);
  };

  const handleFindActiveXMLRelatory = async () => {
    const { data } = await findActiveXMLRelatory();
    setUpdateRelatorys((prevState) => ({
      ...prevState,
      activeRelatory: data,
    }));
  };

  const handleEnableRelatory = async (relatoryId?: number) => {
    if (!relatoryId) {
      return;
    }

    await enableDisableXMLRelatory(relatoryId);
    await Promise.all([
      handleFindAllXMLRelatorys(filters),
      handleFindActiveXMLRelatory(),
    ]);
    toggleConfirmModal();
  };

  useEffect(() => {
    handleFindAllXMLRelatorys(filters);
  }, [filters]);

  useEffect(() => {
    handleFindActiveXMLRelatory();
  }, []);

  return (
    <Container>
      <Toaster />

      <ConfirmModal
        style={{ content: { maxWidth: 800 } }}
        isModalOpen={isModalOpen}
        showConfirmButton={confirmedRelatory !== null}
        onCloseModal={() => {
          setUpdateRelatorys(
            (prevState) =>
              ({
                ...prevState,
                newRelatory: null,
              } as any),
          );
          setConfirmerdRelatory(null);
          toggleConfirmModal();
        }}
        onConfirm={() => handleEnableRelatory(confirmedRelatory?.id)}
        title="Confirmar ativação de XML"
      >
        <b>
          Você realmente deseja ativar esse XML? A ativação desse XML ira criar
          anúncios com base no relatorio gerado!
        </b>

        <p style={{ margin: '1rem 0' }}>
          Apos comparar as informações, selecione o relatorio a ser ativado.
        </p>

        <ComparativeCard
          onSelect={(relatory) => setConfirmerdRelatory(relatory)}
          firstCard={
            <CardContent
              XMLRelatory={updateRelatorys.newRelatory}
              title="Novo Relatorio"
            />
          }
          secondCard={
            <CardContent
              XMLRelatory={updateRelatorys.activeRelatory}
              title="Relatorio Atualmente Ativo"
            />
          }
          firstCardProps={{
            title: 'Novo Relatorio',
            object: updateRelatorys.newRelatory,
          }}
          secondCardProps={{
            object: updateRelatorys.activeRelatory,
            title: 'Relatorio Atualmente Ativo',
          }}
        />
      </ConfirmModal>

      <PageTitle title="Empreendimentos Registrados - XML Gerados" />
      <section className="filters">
        <Select
          name="isActive"
          label="Anuncio"
          onChange={(isActive) =>
            setMyFilters((prevState) => ({
              ...prevState,
              isActive,
            }))
          }
        >
          <MenuItem key="todos" value="todos">
            Todos
          </MenuItem>
          <MenuItem key="ativo" value="true">
            Ativo
          </MenuItem>
          <MenuItem key="inativo" value="false">
            Inativo
          </MenuItem>
        </Select>
      </section>

      {realtorList ? (
        <Card className="list">
          <section className="results">
            <span>Resultados: {XMLRelatorys.length}/</span>
            {totalXMLRelatorys}
          </section>

          <section className="columns-list">
            <p>ID</p>
            <p>TOTAL ANUNCIOS</p>
            <p>ANUNCIOS COMPLETOS</p>
            <p>ANUNCIOS FALTANDO INFO</p>
            <p>ANUNCIOS ECONOMICOS</p>
            <p>ANUNCIOS MÉDIO/ALTO PADRÃO</p>
            <p>NOME DO ARQUIVO</p>
            <p>ATIVO</p>
            <p>GERADO EM</p>
          </section>

          <section className="list-items">
            {XMLRelatorys.length > 0 &&
              XMLRelatorys.map((XMLRelatory: IXMLRelatory) => (
                <section key={XMLRelatory.id} className="list-item">
                  <p title={`${XMLRelatory.id}`}>{XMLRelatory.id}</p>
                  <p>{XMLRelatory.totalAnnouncements}</p>
                  <p>{XMLRelatory.totalSent}</p>
                  <p>{XMLRelatory.lackInfo}</p>
                  <p>{XMLRelatory.economic}</p>
                  <p>{XMLRelatory.highStandard}</p>
                  <p>{XMLRelatory.filename}</p>
                  <p>
                    {' '}
                    <Switch
                      checked={XMLRelatory.isActive}
                      // onChange={() => {
                      //   setUpdateRelatorys((prevState) => ({
                      //     ...prevState,
                      //     newRelatory: XMLRelatory,
                      //   }));
                      //   toggleConfirmModal();
                      // }}
                    />
                  </p>
                  <p>{toLocateDate(XMLRelatory.createdAt)}</p>
                </section>
              ))}
          </section>

          <section className="footer-list">
            <Pagination
              count={Math.round(totalXMLRelatorys / 10) + 1}
              size="small"
              className="pagination"
              onChange={(_: any, page: any) => {
                setCurrentPage(page);
                setMyFilters((prevState) => ({
                  ...prevState,
                  page: String(page),
                }));
              }}
            />
          </section>
        </Card>
      ) : (
        <p className="no-data">Sem registros disponíveis ou permitidos</p>
      )}
    </Container>
  );
};

export default withRouter(EnterprisesZapXmlPage);
