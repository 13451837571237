import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './assets/styles/globalStyles';
import 'assets/styles/custom-rsuit-styles.css';
import DefaultTheme from './assets/themes/theme';
import { AuthProvider } from './contexts/Auth';
import 'react-datetime/css/react-datetime.css';
import Routes from './routes';

const App: React.FC = () => (
  <ThemeProvider theme={DefaultTheme}>
    <GlobalStyles />
    <Router>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </Router>
  </ThemeProvider>
);

export default App;
