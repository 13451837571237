import styled from 'styled-components';

export const NewEnterpriseContainer = styled.section`
  overflow: hidden;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 10px 100px;
`;

export const NewEnterpriseTitle = styled.h1`
  text-align: center;
  font-size: 30px;
  padding: 10px 100px;
  margin-top: 10px;
`;

export const AddFields = styled.button`
  font-family: Inter;
  font-size: 14px;
  margin-top: 2rem;
  font-weight: 400;
  background: #002180;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px;
  transition: filter ease 0.2s;
  width: 200px;
  &:hover {
    filter: brightness(1.2);
  }
`;

export const ButtonContainer = styled.section`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

export const EnterpriseId = styled.p`
  padding: 5px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: 10px;
  width: max-content;
`;

export const Box = styled.section`
  display: flex;
  align-items: center;
`;

export const LinkTo = styled.a`
  background: #002180;
  border-radius: 5px;
  color: #fff;
  padding: 10px;
  margin-top: 10px;
  margin-left: 40px;
  transition: filter ease 0.2s;
  cursor: pointer;
  &:hover {
    filter: brightness(1.2);
  }
`;

export const BackButton = styled.button`
  color: #fff;
  background: #002180;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 5px;
  margin-left: 6rem;
  margin-top: 2rem;
  transition: ease 0.2s;
  &:hover {
    color: #002180;
    background: #fff;
    border: 1px solid #002180;
  }
`;
