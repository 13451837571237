import {
  DashboardOutlined,
  FileCopyOutlined,
  FontDownloadOutlined,
  FormatIndentDecreaseOutlined,
  FormatIndentIncreaseOutlined,
  HouseOutlined,
  PeopleAltOutlined,
  Schedule,
  Create,
  AlternateEmailOutlined,
  ScannerOutlined,
  ListAlt,
  MarkunreadMailboxSharp,
  WhatsApp,
  QuestionAnswer,
  DashboardRounded,
} from '@material-ui/icons';
import { useAuth } from 'contexts/Auth';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { FeaturePermissionEnum } from 'utils/featurePermissionEnum';
import Container from './styles';

const Menu: React.FC = () => {
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [enterprisesSubMenuCollapse, setEnterprisesSubMenuCollapse] =
    useState(false);

  const [hubspotSubMenuCollapse, setHubspotSubMenuCollapse] = useState(false);

  const { hasPermissions } = useAuth();

  const profile = localStorage.getItem('@RNAuth:typeProfile');

  return (
    <Container>
      <ReactTooltip />
      <button
        className="toggle-menu"
        onClick={() => setMenuCollapse(!menuCollapse)}
        type="button"
      >
        {menuCollapse ? (
          <FormatIndentDecreaseOutlined />
        ) : (
          <FormatIndentIncreaseOutlined />
        )}
      </button>
      <ul className={!menuCollapse ? 'active' : ''}>
        <li data-tip="Início" title="Início">
          <NavLink to="/">
            <DashboardOutlined />
            <span data-tip="Início">Início</span>
          </NavLink>
        </li>

        {hasPermissions([FeaturePermissionEnum.HUBSPOT_USERS]) && (
          <li data-tip="Hubspot" title="Hubspot">
            <div
              style={{
                padding: '.4rem .5rem',
                margin: '.5rem 0 0 0',
              }}
            >
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => setHubspotSubMenuCollapse((prev) => !prev)}
                type="button"
              >
                <HouseOutlined
                  style={{
                    marginRight: '.9rem',
                  }}
                />
                <span
                  data-tip="Hubspot"
                  style={{
                    fontWeight: 600,
                    fontSize: '14px',
                  }}
                >
                  Hubspot
                </span>
              </button>

              {hubspotSubMenuCollapse && (
                <>
                  <ReactTooltip />
                  <div>
                    <NavLink to="/hubspot/users">
                      <ListAlt />
                      <span data-tip="Listar Usuarios do Hubspot">
                        Usuarios
                      </span>
                    </NavLink>
                  </div>
                </>
              )}
            </div>
          </li>
        )}

        {hasPermissions([FeaturePermissionEnum.ADMIN]) && (
          <li data-tip="Usuarios" title="Usuarios">
            <NavLink to="/users">
              <PeopleAltOutlined />
              <span data-tip="Leads">Usuarios</span>
            </NavLink>
          </li>
        )}

        {/* Leads */}
        {hasPermissions([FeaturePermissionEnum.LEADS]) && (
          <li data-tip="Leads" title="Leads">
            <NavLink to="/leads">
              <PeopleAltOutlined />
              <span data-tip="Leads">Leads</span>
            </NavLink>
          </li>
        )}

        {hasPermissions([FeaturePermissionEnum.ACTIVE_OFFER]) && (
          <li data-tip="Oferta Ativa" title="Oferta Ativa">
            <NavLink to="/activeOfferLeads">
              <PeopleAltOutlined />
              <span data-tip="Oferta Ativa">Oferta Ativa</span>
            </NavLink>
          </li>
        )}

        {/* Empreendimentos */}
        {hasPermissions([FeaturePermissionEnum.ENTERPRISE]) && (
          <li data-tip="Empreendimentos" title="Empreendimentos">
            <div
              style={{
                padding: '0 .5rem',
              }}
            >
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  setEnterprisesSubMenuCollapse(!enterprisesSubMenuCollapse)
                }
                type="button"
              >
                <HouseOutlined
                  style={{
                    marginRight: '.9rem',
                  }}
                />
                <span
                  data-tip="Empreendimentos"
                  style={{
                    fontWeight: 600,
                    fontSize: '14px',
                  }}
                >
                  Empreendimentos
                </span>
              </button>

              {enterprisesSubMenuCollapse && (
                <>
                  <ReactTooltip />
                  <div>
                    <NavLink to="/enterprises">
                      <ListAlt />
                      <span data-tip="Listar Empreendimentos">Geral *OLD*</span>
                    </NavLink>
                  </div>

                  <div>
                    <NavLink to="/newEnterprises">
                      <ListAlt />
                      <span data-tip="Listar Empreendimentos">Geral *NEW*</span>
                    </NavLink>
                  </div>

                  {hasPermissions([
                    FeaturePermissionEnum.EXPORT_ENTERPRISE_AUDIT,
                  ]) && (
                    <div>
                      <NavLink to="/enterprises/admin/portal/relatory">
                        <FileCopyOutlined />
                        <span data-tip="Relatório Admin Corretor">
                          Admin Corretor
                        </span>
                      </NavLink>
                    </div>
                  )}

                  <div>
                    <NavLink to="/enterprises/aptovc/csv">
                      <FileCopyOutlined />
                      <span data-tip="Relatório APTO.VC">Aptovc CSV</span>
                    </NavLink>
                  </div>

                  <div>
                    <NavLink to="/enterprises/grupozap/xml">
                      <ScannerOutlined />
                      <span data-tip="Zap Anúncios XML">Zap XML</span>
                    </NavLink>
                  </div>
                </>
              )}
            </div>
          </li>
        )}

        {/* Corretores */}
        {hasPermissions([FeaturePermissionEnum.BROKERS]) && (
          <li data-tip="Corretores" title="Corretores">
            <NavLink to="/brokers">
              <PeopleAltOutlined />
              <span data-tip="Corretores">Corretores</span>
            </NavLink>
          </li>
        )}

        {!hasPermissions([FeaturePermissionEnum.ADMIN]) &&
          hasPermissions([FeaturePermissionEnum.RELATORY]) && (
            <li data-tip="Relatorios" title="Relatorios">
              <NavLink to="/relatory">
                <DashboardOutlined />
                <span data-tip="Relatorios">Relatorios</span>
              </NavLink>
            </li>
          )}
      </ul>
    </Container>
  );
};

export { Menu };
