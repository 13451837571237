import * as yup from 'yup';

export const newEnterpriseSchema = yup.object().shape({
  address: yup.object().shape({
    city: yup.string(),
    region: yup.string(),
  }),
  announcements: yup.array().of(
    yup.object().shape({
      announcementUrl: yup.string(),
      id: yup.number(),
      integrationId: yup.string(),
      isActive: yup.boolean(),
      portal: yup.string(),
    }),
  ),
  developer: yup.object().shape({
    name: yup.string(),
  }),
  id: yup.string(),
  integrationId: yup.number(),
  isActive: yup.boolean(),
  name: yup.string(),
  careTeam: yup
    .string()
    .when('type', {
      is: 'PROPERTY',
      then(schema) {
        return schema.required('Atendimento não pode ficar vazio');
      },
      otherwise(schema) {
        return schema.nullable();
      },
    })
    .transform((value) => (value === 'X' ? null : value)),
  propertyIntegrationId: yup.number(),
  type: yup.string(),
});
