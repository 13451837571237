import axios from 'axios';

const find = (enterpriseId: number) =>
  axios.get(`/api/v1/crm/enterprises/${enterpriseId}`);
const validateAnnouncement = (params: any) =>
  axios.post(`/api/v1/crm/enterprises/announcements/validate`, params);

export const EnterprisesApi = {
  find,
  validateAnnouncement,
};
