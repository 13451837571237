/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import axios from 'axios';
import { TextField, InputAdornment } from '@material-ui/core';
import { useLocation, NavLink } from 'react-router-dom';
import queryString from 'query-string';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import PasswordChecklist from 'react-password-checklist';
import Modal from 'react-modal';
import ErrorIcon from './icon/ErrorIcon';
import OkIcon from './icon/OkIcon';
import Container from './styles';

const RegisterPassword = () => {
  const { search } = useLocation();
  const { token, email } = queryString.parse(search);

  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [passwordType, setPasswordType] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState(false);
  const [open, setIsOpen] = useState(false);

  const validations = (value: string) => {
    const notEmpty = value && value !== '';
    const hasUpperLetter = value && value && /[A-Z]/.test(value);
    const notNull = value && value !== null;
    const minimumSize = value && value.length >= 8;
    const hasLetters = value && /[a-z]/i.test(value);
    const hasSpecialChar =
      value && /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value);
    const hasDigit = value && /[\d]/.test(value);
    const isEqual = password === passwordAgain;

    if (
      notEmpty &&
      hasUpperLetter &&
      notNull &&
      minimumSize &&
      hasLetters &&
      hasSpecialChar &&
      hasDigit &&
      isEqual
    ) {
      return true;
    }

    return false;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (validations(password)) {
      await axios.post('/accounts/setPasswordCRM', { token, email, password });
      setIsOpen(true);
    }
  };

  return (
    <Container>
      <header>
        <NavLink to="/login">
          <img src="/logo-aocubo-large.png" alt="logo" />
        </NavLink>
      </header>
      <section className="form-password">
        <form onSubmit={(e) => handleSubmit(e)}>
          <h1>Crie sua nova senha</h1>
          <p>{email}</p>
          <TextField
            type={passwordType ? 'text' : 'password'}
            className="fields"
            label="* Senha"
            name="password"
            variant="outlined"
            size="small"
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => setPasswordType(!passwordType)}
                >
                  {passwordType ? (
                    <Visibility className="visibility" />
                  ) : (
                    <VisibilityOff className="visibility" />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <PasswordChecklist
            className="validations"
            rules={['minLength', 'specialChar', 'number', 'capital']}
            minLength={8}
            value={password}
            valueAgain={passwordAgain}
            messages={{
              minLength: 'A senha deve conter pelo menos 8 dígitos',
              specialChar:
                'A senha deve conter ao menos um caractere especial. Ex: !@#',
              number: 'A senha deve conter ao menos número.',
              capital: 'A senha deve conter ao menos 1 letra maiúscula.',
            }}
            iconComponents={{
              ValidIcon: <OkIcon />,
              InvalidIcon: <ErrorIcon />,
            }}
          />
          <TextField
            type={passwordConfirmation ? 'text' : 'password'}
            className="fields"
            label="* Repita a senha digitada acima"
            name="passwordConfirmation"
            variant="outlined"
            size="small"
            onChange={(e) => setPasswordAgain(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => setPasswordConfirmation(!passwordConfirmation)}
                >
                  {passwordConfirmation ? (
                    <Visibility className="visibility" />
                  ) : (
                    <VisibilityOff className="visibility" />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <PasswordChecklist
            className="validations"
            rules={['match']}
            minLength={8}
            value={password}
            valueAgain={passwordAgain}
            messages={{
              match: 'As senhas devem ser idênticas.',
            }}
            iconComponents={{
              ValidIcon: <OkIcon />,
              InvalidIcon: <ErrorIcon />,
            }}
          />
          <input type="submit" value="Cadastrar" className="submit" />
        </form>
        <img src="./polygon.png" alt="" className="polygon" />
        <img src="./buildings.png" alt="logo" className="buildings" />
        <img src="./polygon.png" alt="" className="right-polygon" />
        <img src="./polygon-large.png" alt="" className="polygon-large" />
      </section>
      <Modal
        isOpen={open}
        className="register-password-modal"
        overlayClassName="Overlay"
      >
        <img src="./check-success.png" alt="Sucesso" />
        <h1>Nova senha registrada!</h1>
        <p>
          Sua nova senha foi registrada com sucesso. Faça seu login para acessar
          ao AoCubo ADM.
        </p>
        <section className="button-container">
          <NavLink to="/login">
            <button type="button">Fazer Login</button>
          </NavLink>
        </section>
        <div className="circle" />
      </Modal>
    </Container>
  );
};

export default RegisterPassword;
