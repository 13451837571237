import React, { createContext, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  createEnterprise,
  findAllEnterprises,
  findEnterprise,
  updateEnterprise,
} from '../../services/enterprises';

interface IErrorMessages {
  [key: string]: string;
}

interface IEnterprisesContext {
  enterprises: any[];
  enterprise: any;
  enterpriseCopy: any;
  totalEnterprises: number;
  portals: any[];
  zones: any[];
  handleSetEnterpriseCopy(enterprise: any): void;
  handleCreateEnterprise: (payload: any) => Promise<void>;
  handleFindAllEnterprises: (queryString?: string) => Promise<void>;
  handleLoadEnterprise: (id: number) => Promise<void>;
  handleUpdateEnterprise: (payload: any) => Promise<void>;
}

const EnterpriseContext = createContext({} as IEnterprisesContext);

const EnterpriseProvider: React.FC = ({ children }) => {
  const [enterprises, setEnterprises] = useState([]);
  const [enterprise, setEnterprise] = useState(undefined);
  const [enterpriseCopy, setEnterpriseCopy] = useState(undefined);
  const [total, setTotal] = useState(0);
  const [totalNew, setTotalNew] = useState(0);
  const portals = [
    {
      value: 'Zap',
      label: 'Zap',
    },
    {
      value: 'AoCubo',
      label: 'AoCubo',
    },
    {
      value: 'MercadoLivre',
      label: 'Mercado Livre',
    },
    {
      value: 'Cv',
      label: 'Cv',
    },
    {
      value: 'Olx',
      label: 'Olx',
    },
    {
      value: 'VivaReal',
      label: 'VivaReal',
    },
    {
      value: 'ImovelWeb',
      label: 'ImovelWeb',
    },
    {
      value: 'Apto.vc',
      label: 'Apto.vc',
    },
  ];

  const zones = [
    {
      value: 'Suzano',
      label: 'Suzano',
    },
    {
      value: 'ABCD',
      label: 'ABCD',
    },
    {
      value: 'Mogi das Cruzes',
      label: 'Mogi das Cruzes',
    },
    {
      value: 'Santos',
      label: 'Santos',
    },
    {
      value: 'Osasco',
      label: 'Osasco',
    },
    {
      value: 'Poá',
      label: 'Poá',
    },
    {
      value: 'Guarulhos',
      label: 'Guarulhos',
    },
    {
      value: 'Cajamar',
      label: 'Cajamar',
    },
    {
      value: 'Campinas',
      label: 'Campinas',
    },
    {
      value: 'São José dos Campos',
      label: 'São José dos Campos',
    },
    {
      value: 'Ribeirão Preto',
      label: 'Ribeirão Preto',
    },
    {
      value: 'Zona Sul',
      label: 'Zona Sul',
    },
    {
      value: 'Zona Oeste',
      label: 'Zona Oeste',
    },
    {
      value: 'Zona Norte',
      label: 'Zona Norte',
    },
    {
      value: 'Zona Leste',
      label: 'Zona Leste',
    },
    {
      value: 'Centro',
      label: 'Centro',
    },
  ];

  const errorMessages: IErrorMessages = {
    'Enterprise already exists': 'ID já existente para o portal o indicado.',
    'Enterprise not found': 'Empreendimento não encontrado.',
  };

  useEffect(() => {
    handleFindAllEnterprises('page=1&limit=10');
  }, []);

  function handleSetEnterpriseCopy(payload: any) {
    setEnterpriseCopy(payload);
  }

  async function handleFindAllEnterprises(queryString?: string) {
    try {
      const { data } = await findAllEnterprises(queryString);
      setEnterprises(data.enterprises);
      setTotal(data.total || 0);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleCreateEnterprise(payload: any) {
    try {
      await createEnterprise(payload);
      toast.success('Empreendimento Criado');
    } catch (error: any) {
      const message = error.response.data.message as string;
      toast.error(errorMessages[message]);
    } finally {
      await handleFindAllEnterprises('page=1&limit=10');
    }
  }

  async function handleUpdateEnterprise(payload: any) {
    try {
      await updateEnterprise(payload);
      toast.success('Empreendimento atualizado');
    } catch (error: any) {
      const message = error.response.data.message as string;
      toast.error(errorMessages[message]);
    } finally {
      await handleFindAllEnterprises('page=1&limit=10');
      setEnterprise(undefined);
    }
  }

  async function handleLoadEnterprise(id: number) {
    try {
      const { data } = await findEnterprise(id);
      setEnterprise(data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <EnterpriseContext.Provider
      value={{
        totalEnterprises: total,
        enterprises,
        enterprise,
        enterpriseCopy,
        portals,
        zones,
        handleSetEnterpriseCopy,
        handleCreateEnterprise,
        handleLoadEnterprise,
        handleFindAllEnterprises,
        handleUpdateEnterprise,
      }}
    >
      {children}
    </EnterpriseContext.Provider>
  );
};

const useEnterpriseContext = () => useContext(EnterpriseContext);

export { EnterpriseProvider, useEnterpriseContext };
