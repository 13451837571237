import styled from 'styled-components';

type ContainerProps = {
  isLoading?: boolean;
};

export const LoadingContainer = styled.section<ContainerProps>`
  filter: ${({ isLoading }) => isLoading && `blur(0.1rem) grayscale(80%);`};
  pointer-events: ${({ isLoading }) => isLoading && 'none'};
`;
