import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Enterprise } from 'models/Enterprise';
import React, { useEffect, useState } from 'react';
import { findAllEnterprises } from 'services/enterprises';

type Props = {
  onSelect: (value: string) => void;
  portal: string;
  portalKey?: string;
};

const AutoCompleteEnterprises: React.FC<Props> = ({
  onSelect,
  portal,
  portalKey = 'name',
}) => {
  const [enterprises, setEnterprises] = useState<Enterprise[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchEnterprises() {
      try {
        setIsLoading(true);
        const { data } = await findAllEnterprises(`portal=${portal}`);
        setEnterprises(data.enterprises);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchEnterprises();
  }, [portal]);

  return (
    <Autocomplete
      options={enterprises}
      disabled={isLoading}
      onChange={(e, value) =>
        onSelect((value?.[portalKey as keyof Enterprise] as string) || '')
      }
      getOptionLabel={(option) => `${option.name}, ${option.enterpriseId}`}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Empreendimento"
          required
          variant="outlined"
        />
      )}
    />
  );
};

export { AutoCompleteEnterprises };
