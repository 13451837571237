import styled from 'styled-components';
import 'typeface-lato';
import 'typeface-inter';

const Container = styled.section`
  height: 100vh;

  header {
    width: max-content;
    padding: 0.5rem 0 0 0.5rem;
    height: min-content;
  }

  .visibility {
    cursor: pointer;
  }

  .buildings {
    position: absolute;
    top: 24rem;
    width: 100px;
  }

  .form-password {
    display: grid;
    grid-template-columns: 1fr 25rem 1fr;
    justify-content: center;
    margin-top: 1rem;
    width: 100%;
    overflow: hidden;

    .polygon {
      position: absolute;
      top: 10rem;
      left: 10rem;
      width: 5rem;
    }

    .right-polygon {
      grid-column: 3;
      position: relative;
      left: 10rem;
      top: 26rem;
      width: 5rem;
    }

    .polygon-large {
      grid-column: 3;
      position: relative;
      top: -23rem;
      left: 21.5rem;
      width: 5rem;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    grid-column: 2;
    z-index: 0;

    h1 {
      font-size: 1.5rem;
      color: #0a1937;
      font-family: Lato;
    }

    p {
      font-size: 18px;
      color: #515151cc;
      font-family: Inter;
      padding: 2rem 0;
    }
  }

  .validations {
    padding: 0 0 1rem 0;
    span {
      font-size: 13px;
      margin-left: 0.5rem;
      margin-top: 0;
    }

    .valid {
      color: #106bf3;
    }

    .invalid {
      color: #eb3b2f;
    }
  }

  .submit {
    background: #002180;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-weight: 700;
    font-family: Inter;
    font-size: 18px;
    padding: 1rem 0;

    &:hover {
      filter: brightness(1.3);
    }
  }
`;

export default Container;
