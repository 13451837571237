export enum JobRolesEnum {
  FINANCE = 'FINANCE',
  OPERATIONS = 'OPERATIONS',
  MARKETING = 'MARKETING',
  FREELANCER = 'FREELANCER',
  PRODUCT = 'PRODUCT',
  MANAGER = 'MANAGER',
  DEVELOPMENT = 'DEVELOPMENT',
  PARTNERS = 'PARTNERS',
  //   HEAD_OF_FINACE = 'HEAD_OF_FINACE',
  //   HEAD_OF_OPERATIONS = 'HEAD_OF_OPERATIONS',
  //   HEAD_OF_MARKETING = 'HEAD_OF_MARKETING',
  //   HEAD_OF_PRODUCT = 'HEAD_OF_PRODUCT',
}
