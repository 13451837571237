/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React, {
  createContext,
  useState,
  useEffect,
  ReactElement,
  useContext,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthApi } from 'services/auth';
import { IUserSign } from 'services/auth/types';
import { ProfileApi } from 'services/profile';
import { IUserProfile } from 'services/profile/types';
import { FeaturePermissionEnum } from 'utils/featurePermissionEnum';

interface AuthContextData {
  signed: boolean;
  typeProfile: string;
  loading: boolean;
  signIn({ email, password }: any): Promise<void>;
  signOut(): void;
  hasPermissions(permissions: string[]): boolean;
}

interface AuthProps {
  children: ReactElement;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider = ({ children }: AuthProps) => {
  const [loading, setLoading] = useState(true);
  const [signed, setSigned] = useState(false);
  const [typeProfile, setTypeProfile] = useState('corretor');
  const [user, setUser] = useState<IUserProfile>();
  const history = useHistory();
  const location = useLocation();
  const publicRoutes = ['/cadastrar-senha', '/login'];
  const currentRoute = publicRoutes
    .filter((item) => item === location.pathname)
    .toString();

  async function loadStoredData() {
    const storedToken = localStorage.getItem('@Auth:token');
    if (storedToken) {
      setSigned(true);
    } else if (storedToken === null && !publicRoutes.includes(currentRoute)) {
      setSigned(false);
      history.push('/login');
    } else {
      setSigned(false);
    }
  }

  const getProfileData = async () => {
    try {
      setLoading(true);
      const { data } = await ProfileApi.index();
      setUser(data);
    } catch (error) {
      setSigned(false);
      setUser(undefined);
      history.push('/login');
    } finally {
      setLoading(false);
    }
  };

  const signIn = async ({ email, password }: any) => {
    setLoading(true);
    const response = await AuthApi.signIn({ email, password });
    const { token, user: userData } = response.data;
    localStorage.setItem('@Auth:token', token);
    localStorage.setItem('@Auth:email', userData.email);
    setLoading(false);
    setSigned(true);
    await getProfileData();
    history.push('/');
  };

  const signOut = async () => {
    localStorage.clear();
    setSigned(false);
    history.push('/login');
  };

  const hasPermissions = (permissions: string[]) => {
    const token = localStorage.getItem('@Auth:token');

    if (!token) {
      return false;
    }

    if (user?.features.includes(FeaturePermissionEnum.ADMIN)) {
      return true;
    }

    return permissions.every((permission) =>
      user?.features.includes(permission),
    );
  };

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    loadStoredData();
  }, []);
  return (
    <AuthContext.Provider
      value={{
        hasPermissions,
        signed: !!signed,
        typeProfile,
        loading,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
