/* eslint-disable no-useless-escape */
import { MenuItem, TextField } from '@material-ui/core';
import { FileCopy, MoreVert, Visibility } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import { Card, PageTitle } from 'components';
import { Select } from 'components/Select';
import { useEnterpriseContext } from 'contexts/Enterprises';
import { useQueryString } from 'hooks';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Popover, Whisper } from 'rsuite';
import { updateLead } from 'services/leadsHubspot';
import { formatPrice } from 'utils/format';
import { NewEnterpriseModal } from './newEnterpriseModal';
import Container from './styles';
import { UpdateEnterpriseModal } from './updateEnterpriseModal';

const EnterprisePage = ({ match }: RouteComponentProps) => {
  const [realtorList, setRealtorList] = useState([]);
  const [leads, setLeads] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(1);
  const [myFilters, setMyFilters] = useState({
    page: String(currentPage),
    limit: String(10),
  });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const {
    enterprises,
    handleLoadEnterprise,
    totalEnterprises,
    portals,
    zones,
    handleFindAllEnterprises,
    handleSetEnterpriseCopy,
  } = useEnterpriseContext();
  const filters = useQueryString(myFilters);
  const [loggedEmail, setLoggedEmail] = useState<string | null>(null);

  useEffect(() => {
    const email = localStorage.getItem('@Auth:email');

    if (email) {
      setLoggedEmail(email);
    }
  }, []);

  const onInputChange = (value: string, key: string) => {
    setMyFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const debouncedInputChangeHandler = useMemo(
    () => debounce(onInputChange, 300),
    [],
  );

  const quantityItems = count >= 7 ? 7 : count;

  const handleUpdateLead = async (id: number, payload: any) => {
    try {
      await updateLead(id, payload);
    } catch (error) {
      console.log(error);
    } finally {
      await handleFindAllEnterprises();
    }
  };

  const handleKeyPress = (e: any, lead: any, email: any) => {
    if (e.key === 'Enter') {
      handleUpdateLead(lead.id, {
        ...lead,
        email,
      });
    }
  };

  const handleOpenEditModal = async (id: number) => {
    await handleLoadEnterprise(id);
    setIsOpenEditModal(true);
  };

  const handleSortBy = (array: any[], property: string) => {
    return array.sort((a, b) => {
      if (a[property] < b[property]) {
        return -1;
      }
      if (a[property] > b[property]) {
        return 1;
      }
      return 0;
    });
  };

  useEffect(() => {
    handleFindAllEnterprises(filters);
  }, [filters]);

  useEffect(() => {
    setCount(enterprises.length);
  });

  return (
    <Container>
      <Toaster />
      <NewEnterpriseModal
        isModalOpen={isOpenModal}
        onCloseModal={() => {
          setIsOpenModal(false);
          handleSetEnterpriseCopy(null);
        }}
      />

      <UpdateEnterpriseModal
        isModalOpen={isOpenEditModal}
        onCloseModal={() => setIsOpenEditModal(false)}
      />

      <PageTitle title="Empreendimentos Registrados" />
      <section className="filters">
        <Select
          name="filter-zones"
          label="Zonas"
          onChange={(zone) =>
            setMyFilters((prevState) => ({
              ...prevState,
              zone,
            }))
          }
        >
          <MenuItem key="todos" value="todos">
            Todos
          </MenuItem>
          {handleSortBy(zones, 'label').map((zone) => (
            <MenuItem key={zone.value} value={zone.value}>
              {zone.label}
            </MenuItem>
          ))}
        </Select>

        <Select
          name="filter-portals"
          label="Portal"
          onChange={(portal) =>
            setMyFilters((prevState) => ({
              ...prevState,
              portal,
            }))
          }
        >
          <MenuItem key="todos" value="todos">
            Todos
          </MenuItem>
          {handleSortBy(portals, 'label').map((portal) => (
            <MenuItem key={portal.value} value={portal.value}>
              {portal.label}
            </MenuItem>
          ))}
        </Select>
        <Select
          name="isPartnerDeveloper"
          label="Empreendimentos de Parceiros"
          onChange={(isPartnerDeveloper) =>
            setMyFilters((prevState) => ({
              ...prevState,
              isPartnerDeveloper,
            }))
          }
        >
          <MenuItem key="todos" value="todos">
            Todos
          </MenuItem>
          <MenuItem key="ativo" value="true">
            Ativos
          </MenuItem>
          <MenuItem key="inativo" value="false">
            Inativos
          </MenuItem>
        </Select>
        <Select
          name="isActive"
          label="Empreendimentos Ativos/Inativos"
          onChange={(isActive) =>
            setMyFilters((prevState) => ({
              ...prevState,
              isActive,
            }))
          }
        >
          <MenuItem key="todos" value="todos">
            Todos
          </MenuItem>
          <MenuItem key="ativo" value="true">
            Ativos
          </MenuItem>
          <MenuItem key="inativo" value="false">
            Inativos
          </MenuItem>
        </Select>
        <TextField
          className="filter-name"
          size="small"
          variant="outlined"
          label="Busca por nome"
          name="filter-name"
          defaultValue=""
          onChange={(e) => debouncedInputChangeHandler(e.target.value, 'name')}
        />
        <TextField
          className="filter-email"
          size="small"
          variant="outlined"
          label="Busca por cidade"
          name="filter-city"
          defaultValue=""
          onChange={(e) => debouncedInputChangeHandler(e.target.value, 'city')}
        />

        <TextField
          className="filter-phone"
          size="small"
          variant="outlined"
          label="Busca por External ID"
          name="filter-enterpriseId"
          defaultValue=""
          onChange={(e) =>
            debouncedInputChangeHandler(e.target.value, 'enterpriseId')
          }
        />
      </section>

      {realtorList ? (
        <Card className="list">
          <section className="results">
            <span>Resultados: {enterprises.length}/</span>
            {totalEnterprises}
          </section>

          <section className="columns-list">
            <p>ID EMP.</p>
            <p>NOME</p>
            <p>CIDADE</p>
            <p>ZONA</p>
            <p>PORTAL</p>
            <p>VALOR</p>
            <p>LINK</p>
            <p>AÇÃO</p>
          </section>

          <section className="list-items">
            {enterprises.length > 0 &&
              enterprises.map((enterprise: any) => (
                <section key={enterprise.id} className="list-item">
                  <p title={`${enterprise.enterpriseId}`}>
                    {enterprise.enterpriseId}
                  </p>
                  <p>{enterprise.name}</p>
                  <p>{enterprise.city}</p>
                  <p>{enterprise.zone}</p>
                  <span>{enterprise.portal}</span>
                  <p>{formatPrice(enterprise.price)}</p>
                  <p>
                    <a
                      href={`${enterprise.link}`}
                      className="url"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {enterprise.link}
                    </a>
                  </p>
                  {/* <p>{enterprise.crmId}</p> */}
                  <Whisper
                    preventOverflow
                    enterable
                    trigger="click"
                    placement="left"
                    speaker={
                      <Popover
                        width="200px"
                        style={{
                          width: '140px',
                          zIndex: 3,
                        }}
                      >
                        <div
                          className="popover-title"
                          style={{
                            width: '100%',
                          }}
                        >
                          <p>AÇÃO</p>
                        </div>
                        <hr />
                        <div className="action-button">
                          <button
                            className="edit-button"
                            type="button"
                            onClick={() => handleOpenEditModal(enterprise.id)}
                          >
                            <Visibility />
                            Editar
                          </button>
                          <button
                            className="edit-button"
                            type="button"
                            onClick={() => {
                              handleSetEnterpriseCopy(enterprise);
                              setIsOpenModal(true);
                            }}
                          >
                            <FileCopy />
                            Duplicar
                          </button>
                        </div>
                      </Popover>
                    }
                  >
                    <MoreVert className="dots" />
                  </Whisper>
                </section>
              ))}
          </section>

          <section className="footer-list">
            <Pagination
              count={Math.round(totalEnterprises / 10) + 1}
              size="small"
              className="pagination"
              onChange={(_, page) => {
                setCurrentPage(page);
                setMyFilters((prevState) => ({
                  ...prevState,
                  page: String(page),
                }));
              }}
            />
          </section>
        </Card>
      ) : (
        <p className="no-data">Sem registros disponíveis ou permitidos</p>
      )}
    </Container>
  );
};

export default withRouter(EnterprisePage);
