/* eslint-disable no-useless-escape */
import { MenuItem, TextField } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import axios from 'axios';
import { Card, PageTitle } from 'components';
import { Select } from 'components/Select';
import { useEnterpriseContext } from 'contexts/Enterprises';
import { useQueryString } from 'hooks';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { NavLink, withRouter } from 'react-router-dom';
import { formatPrice } from 'utils/format';
import { portals, zones } from './constants';
import Container from './styles';

const NewEnterprisePage = () => {
  const [realtorList, setRealtorList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [myFilters, setMyFilters] = useState({
    page: String(currentPage),
    limit: String(10),
  });
  const filters = useQueryString(myFilters);
  const [loggedEmail, setLoggedEmail] = useState<string | null>(null);
  const [enterprisesNew, setEnterprisesNew] = useState([]);
  const [totalNew, setTotalNew] = useState(0);

  const [totalEnterprises, setTotalEnterprises] = useState(0);

  const findAllEnterprisesNew = async (queryString?: string) => {
    return axios.get(`/api/v1/crm/enterprises?${queryString ?? ''}`);
  };

  async function handleFindAllEnterprisesNew(queryString?: string) {
    try {
      const { data } = await findAllEnterprisesNew(queryString);
      setEnterprisesNew(data.content);
      setTotalNew(data.total || 0);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleFindAllEnterprisesNew('page=1&limit=10');
  }, []);

  useEffect(() => {
    const email = localStorage.getItem('@Auth:email');

    if (email) {
      setLoggedEmail(email);
    }
  }, []);

  const onInputChange = (value: string, key: string) => {
    setMyFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const debouncedInputChangeHandler = useMemo(
    () => debounce(onInputChange, 300),
    [],
  );

  const handleSortBy = (array: any[], property: string) => {
    return array.sort((a, b) => {
      if (a[property] < b[property]) {
        return -1;
      }
      if (a[property] > b[property]) {
        return 1;
      }
      return 0;
    });
  };

  const handleTypeName = (type: string) => {
    switch (type) {
      case 'PROPERTY':
        return 'PROPRIEDADE';
      case 'PROPERTY_UNIT':
        return 'UNIDADE';
      default:
        return '#';
    }
  };

  const getTotalEnterprises = async () => {
    const newEnterprises = await axios.get(
      `/api/v1/crm/enterprises?${filters ?? ''}`,
    );

    setTotalEnterprises(newEnterprises.data.totalElements);
  };

  useEffect(() => {
    getTotalEnterprises();
  }, []);

  useEffect(() => {
    handleFindAllEnterprisesNew(filters);
  }, [filters]);

  return (
    <Container>
      <Toaster />

      <PageTitle title="Empreendimentos Registrados" />
      <section className="filters">
        <Select
          name="filter-zones"
          label="Zonas"
          onChange={(zone) =>
            setMyFilters((prevState) => ({
              ...prevState,
              zone,
            }))
          }
        >
          <MenuItem key="todos" value="todos">
            Todos
          </MenuItem>
          {handleSortBy(zones, 'label').map((zone) => (
            <MenuItem key={zone.value} value={zone.value}>
              {zone.label}
            </MenuItem>
          ))}
        </Select>

        <Select
          name="filter-portals"
          label="Portal"
          onChange={(portal) =>
            setMyFilters((prevState) => ({
              ...prevState,
              portal,
            }))
          }
        >
          <MenuItem key="todos" value="todos">
            Todos
          </MenuItem>
          {handleSortBy(portals, 'label').map((portal) => (
            <MenuItem key={portal.value} value={portal.value}>
              {portal.label}
            </MenuItem>
          ))}
        </Select>

        <Select
          name="isPartnerDeveloper"
          label="Parceiros"
          onChange={(isPartnerDeveloper) =>
            setMyFilters((prevState) => ({
              ...prevState,
              isPartnerDeveloper,
            }))
          }
        >
          <MenuItem key="todos" value="todos">
            Todos
          </MenuItem>
          <MenuItem key="ativo" value="true">
            Ativos
          </MenuItem>
          <MenuItem key="inativo" value="false">
            Inativos
          </MenuItem>
        </Select>

        <Select
          name="isActive"
          label="Ativos / Inativos"
          onChange={(isActive) =>
            setMyFilters((prevState) => ({
              ...prevState,
              isActive,
            }))
          }
        >
          <MenuItem key="todos" value="todos">
            Todos
          </MenuItem>
          <MenuItem key="ativo" value="true">
            Ativos
          </MenuItem>
          <MenuItem key="inativo" value="false">
            Inativos
          </MenuItem>
        </Select>
        <TextField
          className="filter-name"
          size="small"
          variant="outlined"
          label="Nome"
          name="filter-name"
          defaultValue=""
          onChange={(e) => debouncedInputChangeHandler(e.target.value, 'name')}
        />
        <TextField
          className="filter-phone"
          size="small"
          variant="outlined"
          label="Nome da Cidade"
          name="filter-enterpriseId"
          defaultValue=""
          onChange={(e) =>
            debouncedInputChangeHandler(e.target.value, 'cityName')
          }
        />

        <TextField
          className="filter-email"
          size="small"
          variant="outlined"
          label="ID Admin"
          name="filter-city"
          defaultValue=""
          onChange={(e) =>
            debouncedInputChangeHandler(e.target.value, 'propertyIntegrationId')
          }
        />
      </section>

      {realtorList ? (
        <Card className="list">
          {/* <section className="results">
            <span>Resultados: {enterprisesNew.length}/</span>
            {totalEnterprises}
          </section> */}

          <section className="columns-list">
            <p>EXTERNAL ID</p>
            <p>NOME</p>
            <p>CIDADE</p>
            <p>ZONA</p>
            <p>VALOR</p>
            <p>TIPO</p>
          </section>

          <section className="list-items">
            {enterprisesNew?.length > 0 &&
              enterprisesNew.map((enterprise: any) => (
                <NavLink
                  key={enterprise.id}
                  to={`/newEnterprises/UpdateNewEnterprise/${enterprise.id}`}
                >
                  <section key={enterprise.id} className="list-item">
                    <p title={`${enterprise.enterpriseId}`}>
                      {enterprise.propertyIntegrationId}
                    </p>
                    <p>{enterprise.name}</p>
                    <p>{enterprise.address.city}</p>
                    <p>{enterprise.address.region || '-'}</p>
                    <p>{formatPrice(enterprise.price)}</p>
                    <p>{handleTypeName(enterprise.type)}</p>
                  </section>
                </NavLink>
              ))}
          </section>

          <section className="footer-list">
            <Pagination
              count={Math.round(totalEnterprises / 10)}
              size="small"
              className="pagination"
              onChange={(_, page) => {
                setCurrentPage(page);
                setMyFilters((prevState) => ({
                  ...prevState,
                  page: String(page),
                }));
              }}
            />
          </section>
        </Card>
      ) : (
        <p className="no-data">Sem registros disponíveis ou permitidos</p>
      )}
    </Container>
  );
};

export default withRouter(NewEnterprisePage);
