import * as yup from 'yup';

export const leadSchema = yup
  .object()
  .shape({
    name: yup.string().min(1).required(),
    email: yup.string().email().required(),
    phone: yup.string(),
    origin: yup.string().required(),
    enterprise: yup.string().required(),
  })
  .required();
