import { FeaturePermissionEnum } from './featurePermissionEnum';
import { JobRolesEnum } from './jobRolesEnum';

export function translateJobRolesEnum(jobRole: JobRolesEnum | string) {
  switch (jobRole) {
    case 'FINANCE': {
      return 'Financeiro';
    }
    case 'OPERATIONS': {
      return 'Operações';
    }
    case 'MARKETING': {
      return 'Marketing';
    }
    case 'MANAGER': {
      return 'Gerente';
    }
    case 'PRODUCT': {
      return 'Produto';
    }
    case 'FREELANCER': {
      return 'Freelacner';
    }
    case 'DEVELOPMENT': {
      return 'Desenvolvedores';
    }
    case 'PARTNERS': {
      return 'Parceiros';
    }

    default:
      return '';
  }
}
export function translateFeatures(feature: FeaturePermissionEnum | string) {
  switch (feature) {
    case 'LEADS': {
      return 'Leads';
    }
    case 'BROKERS': {
      return 'Corretores';
    }
    case 'ENTERPRISE': {
      return 'Empreendimentos';
    }

    case 'ADMIN':
      return 'ADMIN';

    case 'DASHBOARD':
      return 'Dashboard';

    case 'RELATORY':
      return 'Visualizar Relatorio Associado ao Parceiro';

    case 'RELATORIES':
      return 'Visualizar Todos os Relatorios de Parceiros';
    case `HUBSPOT_USERS`:
      return 'Usuarios do Hubspot';
    case 'WASENDER':
      return 'Pode Utilizar a Feature de Wasender';
    case 'ACTIVE_OFFER':
      return 'Pode Utilizar a Feature de Oferta Ativa';
    case 'EXPORT_ENTERPRISE_AUDIT':
      return 'Pode exportar relatórios de atualização de empreendimentos';
    default:
      return '';
  }
}
