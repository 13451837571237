import styled from 'styled-components';
import 'typeface-inter';

const Container = styled.section`
  margin: 0 3rem;
  overflow: hidden;
  width: 93%;

  p.no-data {
    color: #999;
    text-align: center;
    margin: 5rem 0;
  }

  nav {
    padding: 0;
  }

  .button-register {
    display: flex;
    align-items: center;
    color: #002180;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: max-content;

    p {
      display: flex;
      justify-content: center;
      background-color: #002180;
      border-radius: 50%;
      display: flex;
      margin-right: 1rem;
      width: 1.5rem;

      svg {
        color: #fff;
        width: 1rem;
      }
    }
  }

  .dots {
    color: #c5cdda;
    cursor: pointer;
  }

  .results {
    padding-left: 2.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 13px;

    span {
      color: #0a1937;
      font-weight: 600;
    }
  }

  .list {
    box-shadow: 5px 0px 15px 5px rgba(70, 68, 83, 0.1);
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
    padding: 0;
    width: 100%;

    .columns-list {
      display: grid;
      grid-template-columns: 3% 9% 13% 15% 21% 10% 10% 15%;
      grid-gap: 1rem;
      font-size: 12px;
      font-weight: 600;
      color: #121e43;
      background: #f3f7fb;
      padding: 0.5rem 0 0.3rem 2.5rem;
      width: 100%;
    }

    .list-items {
      padding: 0 1px;
      background: #f3f7fb;
      margin: 0 1px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: default;
    }

    p {
      &:first-child {
        color: #0a1937;
        font-weight: 600;
      }
    }
  }

  .list-item {
    display: grid;
    margin: 3px 0;
    background: white;
    grid-template-columns: 3% 9% 13% 15% 21% 10% 9% 5% min-content;
    grid-gap: 1rem;
    align-items: center;
    font-size: 14px;
    padding: 0.5rem 0 0 2rem;
  }

  .list-item:hover {
    background-color: #edf5fa;
  }

  .footer-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem 1.2rem 0.5rem 0;

    p {
      font-size: 13px;
      margin-right: 2rem;
    }

    span {
      color: #0a1937;
      font-weight: 600;
    }
  }

  .filters {
    display: flex;
    margin: 0.5rem 0;
    justify-content: flex-end;

    .filter-manager {
      width: 15%;
      margin-right: 1rem;
    }

    .filter-status {
      width: 10%;
      margin-right: 1rem;
    }

    .filter-name {
      width: 20%;
      margin-right: 1rem;
    }

    .filter-phone {
      margin: 0 1rem;
    }
    .filter-hubspot-id {
      margin: 0;
    }
  }

  .MuiPaginationItem-page.Mui-selected {
    border-radius: 30%;
    background-color: #f3f7fb;
    color: #0a1937;
    font-weight: 600;
  }

  .MuiSwitch-root {
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: #00e6b8;
    }

    .Mui-checked span.MuiSwitch-thumb {
      color: #00e6b8;
    }
  }
`;

export default Container;
