import styled from 'styled-components';

export const Container = styled.section`
  margin: 0 3rem;
  overflow: hidden;
  width: 93%;
`;

export const ErrorText = styled.p`
  color: red;
  font-size: 14px;
`;
