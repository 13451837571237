import React from 'react';

const ErrorIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00016 0.833008C2.70016 0.833008 0.833496 2.69967 0.833496 4.99967C0.833496 7.29967 2.70016 9.16634 5.00016 9.16634C7.30016 9.16634 9.16683 7.29967 9.16683 4.99967C9.16683 2.69967 7.30016 0.833008 5.00016 0.833008ZM5.00016 8.33301C3.1585 8.33301 1.66683 6.84134 1.66683 4.99967C1.66683 3.15801 3.1585 1.66634 5.00016 1.66634C6.84183 1.66634 8.3335 3.15801 8.3335 4.99967C8.3335 6.84134 6.84183 8.33301 5.00016 8.33301Z"
        fill="#EB3B2F"
      />
    </svg>
  );
};

export default ErrorIcon;
