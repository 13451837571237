import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { ButtonEnterprise } from 'components';
import { Select } from 'components/Select';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';
import { HubspotApi } from 'services/hubspot';
import { HubspotUser } from 'services/hubspot/types';
import { hubspotUserSchema } from 'validations/hubspotUserSchema';
import { ErrorText } from '../styles';

type Props = {
  id: number;
  preloadData: HubspotUser;
};

export const managersData = [
  {
    managerName: 'Cecilia',
    activeTeamId: '37180005',
    inactiveTeamId: '37180005',
    pipelineId: '25520020',
    roleId: '651333',
  },
  {
    managerName: 'Ricardo',
    activeTeamId: '36065064',
    inactiveTeamId: '36065064',
    pipelineId: '25520020',
    roleId: '651333',
  },
  {
    managerName: 'Projeto FL2',
    activeTeamId: '35674181',
    inactiveTeamId: '35674181',
    pipelineId: '51394832',
    roleId: '651333',
  },
];

export const HubspotUserUpdateForm: React.FC<Props> = ({ id, preloadData }) => {
  const { register, handleSubmit, watch, setValue, control, formState } =
    useForm({
      resolver: yupResolver(hubspotUserSchema),
      defaultValues: preloadData,
    });

  const history = useHistory();

  const onSubmit = async (user: HubspotUser) => {
    const managerInfos = managersData.find(
      (manager) => manager.managerName === user?.manager,
    );

    try {
      const payload = { ...user, totalSales: Number(user.totalSales) };

      if (managerInfos) {
        Object.assign(payload, {
          activeHubspotTeamId: managerInfos.activeTeamId,
          inactiveHubspotTeamId: managerInfos.inactiveTeamId,
          roleId: managerInfos.roleId,
          receiveLeadsFromPipelineId: managerInfos.pipelineId,
        });
      }

      await HubspotApi.update(Number(id), payload);

      const message = `Usuario Atualizado`;

      toast.success(message);
      history.push('/hubspot/users');
    } catch (error) {
      toast.error('Nao foi possivel cadastrar o usuario');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" xs={5}>
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              label="Nome do Usuario"
              size="small"
              variant="outlined"
            />
          )}
        />

        <TextField
          size="small"
          variant="outlined"
          margin="normal"
          label="Email"
          disabled
          InputLabelProps={{
            shrink: true,
          }}
          {...register('email')}
        />

        <TextField
          size="small"
          variant="outlined"
          margin="normal"
          label="Telefone"
          placeholder="Exemplo: 11912345678"
          InputLabelProps={{
            shrink: true,
          }}
          {...register('phone')}
        />
        {formState.errors.phone && (
          <ErrorText>{formState.errors.phone?.message}</ErrorText>
        )}

        <TextField
          size="small"
          variant="outlined"
          margin="normal"
          label="Contatos com o usuarios"
          disabled
          InputLabelProps={{
            shrink: true,
          }}
          {...register('totalContacts')}
        />

        <TextField
          size="small"
          variant="outlined"
          type="number"
          margin="normal"
          label="Número de Conversões"
          InputLabelProps={{
            shrink: true,
          }}
          {...register('totalSales')}
        />

        <TextField
          size="small"
          variant="outlined"
          margin="normal"
          label="Grupo"
          //   InputLabelProps={{
          //     shrink: true,
          //   }}
        />

        <div style={{ marginTop: 15 }} />

        {watch('isBroker') && (
          <Select
            label="Gerente"
            name="manager"
            onChange={(e) => setValue('manager', e)}
            defaultValue={
              managersData.find(
                (data) => data.activeTeamId === preloadData.activeHubspotTeamId,
              )?.managerName
            }
          >
            {managersData.map((manager) => (
              <MenuItem key={manager.managerName} value={manager.managerName}>
                {manager.managerName}
              </MenuItem>
            ))}
          </Select>
        )}
        {formState.errors.manager && (
          <ErrorText style={{ marginTop: 10 }}>
            {formState.errors.manager?.message}
          </ErrorText>
        )}

        <Grid container direction="row">
          <FormControlLabel
            control={
              <Controller
                name="isOnRollete"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Checkbox onChange={onChange} checked={value} value={value} />
                )}
              />
            }
            label="Usuario Na Roleta"
          />

          <FormControlLabel
            control={
              <Controller
                name="isBroker"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    onChange={(e) => {
                      onChange(e);
                      setValue('manager', undefined);
                    }}
                    checked={value}
                    value={value}
                  />
                )}
              />
            }
            label="É um corretor"
          />

          <FormControlLabel
            // key={watch('isActiveOnHubspot')}
            control={
              <Controller
                name="isActiveOnHubspot"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Checkbox onChange={onChange} checked={value} disabled />
                )}
              />
            }
            label="Ativo no hubspot"
          />
        </Grid>
      </Grid>

      <ButtonEnterprise type="submit">Atualizar</ButtonEnterprise>
    </form>
  );
};
