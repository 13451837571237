import React from 'react';
import { WarningOutlined } from '@material-ui/icons';
import { Container } from './styles';

type Props = {
  error: string;
};

export const ErrorLabel: React.FC<Props> = ({ error }) => {
  return (
    <Container>
      <WarningOutlined />
      <span>{error}</span>
    </Container>
  );
};
