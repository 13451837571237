/* eslint-disable no-useless-escape */
import { TextField } from '@material-ui/core';
import { ButtonEnterprise, Card, PageTitle } from 'components';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { getEnterpriseAdminRealtorCsv } from 'services/enterprises';
import Datetime from 'react-datetime';
import { yupResolver } from '@hookform/resolvers/yup';
import { enterprisePortalSchema } from 'validations/enterprisesPortal.schema';
import Container from './styles';

type FilterType = {
  filterStart: Date;
  filterEnd: Date;
};

const EnterpriseAdminRealtorCSVPage = () => {
  const handleDownloadAdminRealtorCSV = async (start: string, end: string) => {
    try {
      const { data } = await getEnterpriseAdminRealtorCsv(start, end);

      const blob = new Blob([data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${Date.now()}-admin-corretor.xlsx`;

      link.click();
    } catch (error) {
      toast.error('Falha ao baixar CSV');
    }
  };

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(enterprisePortalSchema),
  });

  const handleFilterRealtorAdmin = async (value: FilterType) => {
    handleDownloadAdminRealtorCSV(
      value.filterStart.toISOString(),
      value.filterEnd.toISOString(),
    );
  };

  return (
    <Container>
      <Toaster />
      <PageTitle title="Relatorio em CSV dos Empreendimentos Portal do Corretor" />

      <Card>
        <div>
          <form onSubmit={handleSubmit(handleFilterRealtorAdmin)}>
            <div className="filter-container">
              <Controller
                name="filterStart"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="date"
                    variant="outlined"
                    label="Período Incial"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
              <Controller
                name="filterEnd"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="date"
                    variant="outlined"
                    label="Período Final"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </div>
            {/* <h4>Quantidade de Empreendimentos: {enterprisesCount}</h4> */}
            <ButtonEnterprise type="submit">Baixar Relatório</ButtonEnterprise>
          </form>
        </div>
      </Card>
    </Container>
  );
};

export default withRouter(EnterpriseAdminRealtorCSVPage);
