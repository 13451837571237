/* eslint-disable react/prop-types */
import { MenuItem, TextField } from '@material-ui/core';
import React from 'react';

// import { Container } from './styles';
type Props = {
  onChange: (value: string) => void;
};

const SelectType: React.FC<Props> = ({ onChange }) => {
  return (
    <TextField
      select
      size="small"
      variant="outlined"
      className="filter-status"
      label="Tipo do Cliente"
      name="filter-type-lead"
      defaultValue="todos"
      onChange={(e) =>
        onChange(e.currentTarget.getAttribute('data-value') || '')
      }
    >
      <MenuItem key="todos" value="todos">
        Todos
      </MenuItem>
      <MenuItem key="CUSTOMER" value="CUSTOMER">
        Lead
      </MenuItem>
      <MenuItem key="BROKER" value="BROKER">
        Corretor
      </MenuItem>
    </TextField>
  );
};

export { SelectType };
