import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config({
  path: '.env',
});

const baseURL =
  process.env.REACT_APP_URL_API_BACKEND ?? 'http://localhost:3333';

axios.defaults.baseURL = baseURL;

axios.interceptors.request.use(
  (request) => {
    const storedToken = localStorage.getItem('@Auth:token');
    if (storedToken && !request.url?.includes('cep')) {
      request.headers = {
        Authorization: `Bearer ${storedToken}`,
      };
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);
