import * as yup from 'yup';

export const changePasswordSchema = yup
  .object()
  .shape({
    password: yup.string().required(),
    newPassword: yup.string().required(),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  })
  .required();
