import styled from 'styled-components';

const Container = styled.section`
  .lines {
    display: flex;
    .blue {
      background-color: #002180;
      border: 1px solid #002180;
      width: 240px;
    }

    hr {
      background-color: #e8eaed;
      border: 1px solid #e8eaed;
      width: 92%;
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;

    h1 {
      color: #515151;
      font-family: Inter;
      font-size: 14px;
      margin-top: 2rem;
      font-weight: 400;
      width: 230px;
    }

    button {
      color: #ffffff;
      font-family: Inter;
      font-size: 14px;
      margin-top: 2rem;
      font-weight: 400;

      background: #002180;
      padding: 11px 67px;
      border-radius: 0.5rem;

      transition: filter ease 0.2s;

      &:hover {
        filter: brightness(1.2);
      }
    }
  }
`;

export default Container;
