/* eslint-disable no-useless-escape */
import { MenuItem, TextField } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import {
  MoreVert,
  WarningOutlined,
  EditAttributesOutlined,
} from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import { Card, PageTitle, ConfirmModal } from 'components';
import { Select } from 'components/Select';
import { useQueryString } from 'hooks';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Popover, Whisper } from 'rsuite';
import { UserApi } from 'services/users';
import { IUser } from 'services/users/type';
import { translateJobRolesEnum } from 'utils/translateEnum';
import { Container } from './styles';

const UsersIndexPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [myFilters, setMyFilters] = useState({
    page: String(currentPage),
    limit: String(10),
    isActive: 'todos',
  });
  const filters = useQueryString(myFilters);
  const [users, setUsers] = useState<IUser[]>([]);
  const [paginationInfo, setPaginationInfo] = useState({
    totalElements: 0,
    totalPages: 0,
    size: 0,
  });
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [userId, setUserId] = useState<string>();
  const history = useHistory();

  const onInputChange = (value: string, key: string) => {
    setMyFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const debouncedInputChangeHandler = useMemo(
    () => debounce(onInputChange, 300),
    [],
  );

  const handleConfirmModalSubmit = async () => {
    await UserApi.forceReset(userId as string);
    await handleFindAllUsers(filters);
    setIsConfirmModalOpen(false);
    setUserId('');
  };

  const handleFindAllUsers = async (qs?: string) => {
    try {
      const { data } = await UserApi.index(qs);
      const { content, ...rest } = data;
      setUsers(content);
      setPaginationInfo({
        size: rest.size,
        totalElements: rest.totalElements,
        totalPages: rest.totalPages,
      });
    } catch (error) {
      toast.error('Houve um erro ao carregar a lista de usaurios');
    }
  };

  useEffect(() => {
    handleFindAllUsers(filters);
  }, [filters]);

  return (
    <Container>
      <PageTitle title="Usuarios do AocuboCRM">
        <button type="button" onClick={() => history.push(`/users/create`)}>
          Cadastrar Usuario
        </button>
      </PageTitle>

      <Toaster />

      <ConfirmModal
        title="Confirmar Reset da senha"
        isModalOpen={isConfirmModalOpen}
        onCloseModal={() => {
          setIsConfirmModalOpen(false);
          setUserId('');
        }}
        onConfirm={() => handleConfirmModalSubmit()}
      >
        <p>A Nova senha do usuario ira ser reenvida pelo email cadastrado.</p>
      </ConfirmModal>

      <section className="filters">
        <Select
          label="Ativo/Inativo"
          name="isActive"
          onChange={(isActive) => {
            setMyFilters((prevState) => ({
              ...prevState,
              isActive,
            }));
          }}
        >
          <MenuItem value="todos">Todos</MenuItem>
          <MenuItem value="true">Ativos</MenuItem>
          <MenuItem value="false">Inativos</MenuItem>
        </Select>
        <TextField
          className="filter-name"
          size="small"
          variant="outlined"
          label="Busca por nome"
          name="filter-name"
          defaultValue=""
          onChange={(e) => debouncedInputChangeHandler(e.target.value, 'name')}
        />
        <TextField
          className="filter-email"
          size="small"
          variant="outlined"
          label="Busca por e-mail"
          name="filter-email"
          defaultValue=""
          onChange={(e) => debouncedInputChangeHandler(e.target.value, 'email')}
        />
      </section>

      {users.length ? (
        <Card className="list">
          <section className="results">
            <span>Resultados: {users?.length ?? 0}/</span>
            {paginationInfo.totalElements}
          </section>

          <section className="columns-list">
            <p>ID</p>
            <p>NOME</p>
            <p>E-MAIL</p>
            <p>FUNÇÃO</p>
            <p>ATIVO</p>
          </section>

          <section className="list-items">
            {users.length > 0 &&
              users.map((user: IUser) => (
                <section key={user.id} className="list-item">
                  <p>{user.id}</p>
                  <p>{user.name}</p>
                  <p>{user.email}</p>
                  <p>{translateJobRolesEnum(user.jobRole)}</p>
                  <p>
                    <Switch checked={user.isActive} disabled />
                  </p>

                  <Whisper
                    preventOverflow
                    enterable
                    trigger="click"
                    placement="left"
                    speaker={
                      <Popover
                        width="300px"
                        style={{
                          width: '240px',
                          zIndex: 4,
                        }}
                      >
                        <div
                          className="popover-title"
                          style={{
                            width: '100%',
                          }}
                        >
                          <p>AÇÃO</p>
                        </div>
                        <hr />
                        <div className="action-button">
                          <button
                            className="edit-button"
                            type="button"
                            onClick={() =>
                              history.push(`/users/create/${user.id}`)
                            }
                          >
                            <EditAttributesOutlined />
                            Editar
                          </button>
                          <button
                            className="edit-button"
                            onClick={() => {
                              setIsConfirmModalOpen(true);
                              setUserId(user.id);
                            }}
                            type="button"
                          >
                            <WarningOutlined />
                            Resetar Senha
                          </button>
                        </div>
                      </Popover>
                    }
                  >
                    <MoreVert className="dots" />
                  </Whisper>
                </section>
              ))}
          </section>

          <section className="footer-list">
            <Pagination
              count={paginationInfo.totalPages}
              size="small"
              className="pagination"
              onChange={(_, page) => {
                setCurrentPage(page);
                setMyFilters((prevState) => ({
                  ...prevState,
                  page: String(page),
                }));
              }}
            />
          </section>
        </Card>
      ) : (
        <p className="no-data">Sem registros disponíveis ou permitidos</p>
      )}
    </Container>
  );
};

export default withRouter(UsersIndexPage);
