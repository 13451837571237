export enum FeaturePermissionEnum {
  ADMIN = 'ADMIN',
  ENTERPRISE = `ENTERPRISE`,
  DASHBOARD = `DASHBOARD`,
  BROKERS = `BROKERS`,
  LEADS = `LEADS`,
  WASENDER = `WASENDER`,
  RELATORY = 'RELATORY',
  RELATORIES = 'RELATORIES',
  HUBSPOT_USERS = 'HUBSPOT_USERS',
  ACTIVE_OFFER = 'ACTIVE_OFFER',
  EXPORT_ENTERPRISE_AUDIT = 'EXPORT_ENTERPRISE_AUDIT',
}
