export const portals = [
  {
    value: 'Zap',
    label: 'Zap',
  },
  {
    value: 'AoCubo',
    label: 'AoCubo',
  },
  {
    value: 'MercadoLivre',
    label: 'Mercado Livre',
  },
  {
    value: 'Yellow Pepper',
    label: 'Yellow Pepper',
  },
  {
    value: 'Olx',
    label: 'Olx',
  },
  {
    value: 'VivaReal',
    label: 'VivaReal',
  },
  {
    value: 'ImovelWeb',
    label: 'ImovelWeb',
  },
  {
    value: 'Apto.vc',
    label: 'Apto.vc',
  },
  {
    value: 'AppMeuImovel',
    label: 'AppMeuImovel',
  },
];

export const zones = [
  {
    value: 'Suzano',
    label: 'Suzano',
  },
  {
    value: 'ABCD',
    label: 'ABCD',
  },
  {
    value: 'Mogi das Cruzes',
    label: 'Mogi das Cruzes',
  },
  {
    value: 'Santos',
    label: 'Santos',
  },
  {
    value: 'Osasco',
    label: 'Osasco',
  },
  {
    value: 'Poá',
    label: 'Poá',
  },
  {
    value: 'Guarulhos',
    label: 'Guarulhos',
  },
  {
    value: 'Cajamar',
    label: 'Cajamar',
  },
  {
    value: 'Campinas',
    label: 'Campinas',
  },
  {
    value: 'São José dos Campos',
    label: 'São José dos Campos',
  },
  {
    value: 'Ribeirão Preto',
    label: 'Ribeirão Preto',
  },
  {
    value: 'Zona Sul',
    label: 'Zona Sul',
  },
  {
    value: 'Zona Oeste',
    label: 'Zona Oeste',
  },
  {
    value: 'Zona Norte',
    label: 'Zona Norte',
  },
  {
    value: 'Zona Leste',
    label: 'Zona Leste',
  },
  {
    value: 'Centro',
    label: 'Centro',
  },
];
