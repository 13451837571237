import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  Dashboard,
  AppsOutlined,
  NotificationsNone,
  ExpandMore,
  HelpOutline,
  AccountCircleOutlined,
  LockOutlined,
} from '@material-ui/icons';
import AuthContext from 'contexts/Auth';
import { ConfirmModal } from 'components/ConfirmModal';

import { InputPassword } from 'components/InputPassword';
import { Grid } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { changePasswordSchema } from 'validations/changePasswordSchema';
import { ProfileApi } from 'services/profile';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router';
import ButtonEnterprise from 'components/ButtonEnterprise';
import { Container } from './style';

const Header: React.FC = () => {
  const { signOut } = useContext(AuthContext);
  const [showProfileSubMenu, setShowProfileSubMenu] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const { handleSubmit, control, watch } = useForm({
    resolver: yupResolver(changePasswordSchema),
  });
  const history = useHistory();
  const subMenuRef = useRef<any>();

  const onSubmit = async (data: any) => {
    try {
      await ProfileApi.changePassword(data);
      toast.success(`Senha Alterada`);
    } catch (error) {
      toast.error(`Nao foi possivel trocar a senha`);
    } finally {
      setShowResetPasswordModal(false);
    }
  };

  const toggleProfileSubMenu = () => setShowProfileSubMenu((prev) => !prev);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        showProfileSubMenu &&
        subMenuRef.current &&
        !subMenuRef.current?.contains(e.target)
      ) {
        setShowProfileSubMenu(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showProfileSubMenu]);

  return (
    <Container>
      <Toaster />
      <ConfirmModal
        title="Troca de senha"
        isModalOpen={showResetPasswordModal}
        onConfirm={() => ({})}
        showButtons={false}
        onCloseModal={() => setShowResetPasswordModal(false)}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" spacing={2} xs={12}>
            <Grid item md={12}>
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <InputPassword label="Senha Atual" {...field} />
                )}
              />
            </Grid>

            <Grid item>
              <Controller
                control={control}
                name="newPassword"
                render={({ field }) => (
                  <InputPassword label="Nova Senha" {...field} />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="confirmPassword"
                render={({ field }) => (
                  <InputPassword label="Confirme a Nova Senha" {...field} />
                )}
              />
            </Grid>

            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <button
                type="button"
                onClick={() => setShowResetPasswordModal(false)}
              >
                Cancelar
              </button>
              <ButtonEnterprise type="submit">Alterar</ButtonEnterprise>
            </Grid>
          </Grid>
        </form>
      </ConfirmModal>
      <div className="logo">
        <img src="/logo-aocubo-large.png" alt="aocubo" />
      </div>
      <div className="flex-mid">
        <span className="icon-dashboard">
          <Dashboard style={{ color: '#00e6b8' }} />
          Inicio
        </span>
        <div className="menu-icons">
          <NotificationsNone />
          <HelpOutline />
          <button type="button" onClick={toggleProfileSubMenu}>
            <AccountCircleOutlined />
            <ExpandMore />
          </button>
          {showProfileSubMenu && (
            <div className="profile-submenu" ref={subMenuRef}>
              <div>
                <button type="button" onClick={() => history.push('/profile')}>
                  <AccountCircleOutlined />
                  <p>Perfil</p>
                </button>
              </div>
              <hr />
              <div>
                <button
                  onClick={() => setShowResetPasswordModal(true)}
                  type="button"
                >
                  <LockOutlined style={{ marginRight: 3 }} />
                  <p>Trocar senha</p>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="sing-out">
        <button type="button" onClick={signOut}>
          Sair
        </button>
      </div>
    </Container>
  );
};

export default Header;
