/* eslint-disable no-useless-escape */
import {
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Button,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Box,
  Chip,
  Typography,
  Switch,
} from '@material-ui/core';
import { ButtonEnterprise, PageTitle } from 'components';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { withRouter } from 'react-router-dom';
import { JobRolesEnum } from 'utils/jobRolesEnum';
import { translateFeatures, translateJobRolesEnum } from 'utils/translateEnum';
import { useHistory, useParams } from 'react-router';
import { UserApi } from 'services/users';
import { IFeature, IUser } from 'services/users/type';

import { userSchema } from 'validations/user.schema';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProfileApi } from 'services/profile';
import { IUserProfile } from 'services/profile/types';
import { Container } from './styles';

const ProfilePage = () => {
  const [roles, setRoles] = useState<string[]>([]);
  const history = useHistory();
  const jobRoles = Object.keys(JobRolesEnum) as JobRolesEnum[];
  const [selectedFeatures, setSelectedFeatures] = useState<any[]>([]);
  const [profile, setProfile] = useState<IUserProfile>();

  const { register, handleSubmit, reset, watch, setValue, control } = useForm({
    resolver: yupResolver(userSchema),
  });

  const onSubmit = async (user: any) => {
    try {
      const featuresIds = selectedFeatures.map(
        (selectedFeature) => selectedFeature.id,
      );
      const payload = { ...user, featuresIds };

      toast.success('');
      history.push('/users');
    } catch (error) {
      toast.error('Nao foi possivel cadastrar o usuario');
    }
  };

  const populateForm = (user: IUser) => {
    Object.entries(user).forEach(([key, value]) => {
      setValue(key, value);
    });
    setSelectedFeatures(user.features);
  };

  const handleFindProfile = async () => {
    try {
      const { data } = await ProfileApi.index();
      setProfile(data);
      // populateForm(data);
    } catch (error) {
      toast.error(`Nao foi possivel encontrar o usuario`);
    }
  };

  useEffect(() => {
    // handleFindFeatures();

    // if (id) {
    handleFindProfile();
    // }
  }, []);

  return (
    <Container>
      <PageTitle title="Usuarios do AocuboCRM">
        <button type="button" onClick={() => history.push('/users')}>
          Voltar
        </button>
      </PageTitle>

      <Toaster />

      <form onSubmit={handleSubmit(onSubmit)}>
        {watch('isActive')}
        <Grid container direction="column" xs={5}>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange } }) => (
              <TextField
                onChange={onChange}
                value={profile?.name}
                aria-readonly
                InputLabelProps={{
                  shrink: true,
                }}
                label="Nome do Usuario"
                size="small"
                variant="outlined"
              />
            )}
          />

          <TextField
            size="small"
            variant="outlined"
            margin="normal"
            label="Email"
            aria-readonly
            InputLabelProps={{
              shrink: true,
            }}
            value={profile?.email}
          />

          <Controller
            name="jobRole"
            control={control}
            key={Math.random()}
            render={({ field: { onChange, value } }) => (
              <TextField
                size="small"
                variant="outlined"
                margin="normal"
                label="Cargo"
                onChange={onChange}
                aria-readonly
                value={translateJobRolesEnum(profile?.jobRole ?? '')}
                key={jobRoles.length}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />

          <Typography>Tem acesso a:</Typography>
          {profile?.features.map((feature) => (
            <FormControlLabel
              {...register('features')}
              key={feature}
              value={selectedFeatures}
              label={translateFeatures(feature)}
              control={<Checkbox aria-readonly checked />}
            />
          ))}
        </Grid>
      </form>
    </Container>
  );
};

export default withRouter(ProfilePage);
