/* eslint-disable react/jsx-props-no-spreading */
import { Button } from '@material-ui/core';
import React from 'react';
import ReactModal from 'react-modal';

type Props = {
  title: string;
  isModalOpen: boolean;
  onCloseModal: () => void;
  onConfirm: () => void;
  style?: any;
  showConfirmButton?: boolean;
  showButtons?: boolean;
};

const ConfirmModal: React.FC<Props> = ({
  isModalOpen,
  onCloseModal,
  onConfirm,
  title,
  style,
  showConfirmButton = true,
  showButtons = true,
  children,
}) => {
  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={onCloseModal}
      appElement={document.getElementById('root') || undefined}
      className="react-modal-content"
      style={style}
      overlayClassName="react-modal-overlay"
    >
      <h1>{title}</h1>
      <div
        style={{
          margin: '1rem 0',
        }}
      >
        {children}
      </div>

      {showButtons && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button variant="text" onClick={onCloseModal}>
            Cancelar
          </Button>
          {showConfirmButton ? (
            <Button
              onClick={onConfirm}
              variant="contained"
              color="primary"
              type="submit"
              style={{
                color: '#ffffff',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 400,
                background: '#002180',
                borderRadius: '0.5rem',
                transition: 'filter ease 0.2s',
              }}
            >
              Continuar
            </Button>
          ) : null}
        </div>
      )}
    </ReactModal>
  );
};

ConfirmModal.defaultProps = {
  isModalOpen: undefined,
  onCloseModal: undefined,
  onConfirm: undefined,
  style: undefined,
  title: '',
};

export { ConfirmModal };
