/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import axios from 'axios';
import { IAuthResponse } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function signIn({ email, password }: any) {
  return axios.post<IAuthResponse>('/accounts/sessions', { email, password });
}

export const AuthApi = {
  signIn,
};
