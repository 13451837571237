import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5rem;
  background: #f3f7fb;
  overflow: hidden;
  .page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;

    .date-container {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 1rem;
      margin-bottom: 1rem;

      .label {
        display: flex;
        flex-direction: column;

        p {
          color: #2e3237;
          font-family: 'Lato';
          font-weight: 600;
          margin-bottom: 0.2rem;
        }

        input {
          width: 100%;
        }
      }
    }

    .grid-container {
      display: grid;
      grid-template-columns: repeat(4, 14.3rem);
      grid-gap: 1rem;
      /* place-items: center; */
      text-align: center;
      align-items: center;

      .items {
        background: #fff;
        border: 1px solid #002180;
        border-radius: 5px;
        padding: 0.8rem 0;

        .total {
          color: #002180;
          font-family: 'Inter';
          font-size: 2.8rem;
          font-weight: 900;
        }

        .subtitle {
          color: #7f8895;
          font-family: 'Lato';
          font-size: 0.7rem;
          font-weight: 600;
        }
      }
    }
  }

  .icon-date {
    position: absolute;
    color: #002180;
    z-index: 1;
  }

  .rdt.input-date > input {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
  }

  .date-container {
    display: flex;
    flex-direction: row-reverse;
  }

  .rdt.input-tax-date > input {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .icon-tax-date {
    color: #002180;
    margin-top: 0.3rem;
    margin-right: 1rem;
    z-index: 1;
  }

  .teste {
    /* background: #fff; */
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    padding-top: 3rem;
    box-shadow: 2px 0px 8px rgba(149, 170, 173, 0.25);

    .rdtPicker {
      margin-right: 5rem;
    }

    .title {
      color: #7f8895;
      font-size: 0.8rem;
      font-family: 'Lato';
      margin-bottom: 1rem;
    }

    .text-label {
      font-size: 0.7rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    .input-status {
      margin-bottom: 1rem;
      width: 80%;
    }

    .date {
      width: 300px;
      margin: 0.5rem 0;
    }

    .buttonContainer {
      display: flex;
      justify-content: space-between;
      width: 300px;

      button:nth-of-type(2) {
        background: red;
      }
    }

    .conversion-date {
      display: flex;
      flex-direction: column;

      section {
        width: min-content;
        p {
          font-size: 0.6rem;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }

        input:nth-child(1) {
          margin-bottom: 1rem;
        }
      }
    }

    .apply-button {
      background: #002180;
      border-radius: 5px;
      color: #fff;
      font-family: 'Inter';
      padding: 0.5rem 1.5rem;
      margin-top: 1rem;
      margin-bottom: 2rem;
      width: 6rem;
    }

    .percent {
      border: 1px solid #002180;
      border-radius: 5px;
      color: #002180;
      font-family: 'Inter';
      font-size: 2.8rem;
      font-weight: 600;
      padding: 0 1rem;
      width: min-content;
    }

    .react-select-container {
      width: 15rem;
      margin-bottom: 1rem;
    }
  }
`;

export default Container;
