/* eslint-disable react/prop-types */
import { MenuItem, TextField } from '@material-ui/core';
import React from 'react';

// import { Container } from './styles';
const origins = [
  { id: 1, key: 'AoCubo', value: 'aoCubo' },
  { id: 2, key: 'VivaReal', value: 'vivareal' },
  { id: 3, key: 'Zap', value: 'zap' },
  { id: 4, key: 'Mercado Livre', value: 'mercadoLivre' },
  { id: 5, key: 'OLX', value: 'olx' },
  { id: 6, key: 'Cv', value: 'cv' },
  { id: 7, key: '123i', value: '123i' },
  { id: 8, key: 'Apto.vc', value: 'apto.vc' },
  { id: 9, key: 'AppMeuImovel', value: 'AppMeuImovel' },
  { id: 10, key: 'Yellow Pepper', value: 'Yellow Pepper' },
];

type Props = {
  onChange: (value: string) => void;
};

const SelectOrigin: React.FC<Props> = ({ onChange }) => {
  return (
    <TextField
      select
      size="small"
      variant="outlined"
      className="filter-manager"
      label="Origem"
      name="filter-manager"
      defaultValue="todos"
      onChange={(e) =>
        onChange(e.currentTarget.getAttribute('data-value') || '')
      }
    >
      <MenuItem value="todos">Todos</MenuItem>
      {origins.map((origin) => (
        <MenuItem key={origin.id} value={origin.value}>
          {origin.key}
        </MenuItem>
      ))}
    </TextField>
  );
};

export { SelectOrigin };
