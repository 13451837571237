import axios from 'axios';

const findAllLeads = async (queryString?: string) => {
  return axios.get(`/leads?${queryString ?? ''}`);
};

const createLead = async (lead: any) => {
  return axios.post('/leads', lead);
};

const updateLead = async (leadId: number, lead: any) => {
  return axios.put(`/leads/${leadId}`, lead);
};

export { findAllLeads, updateLead, createLead };
