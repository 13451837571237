/* eslint-disable no-useless-escape */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField } from '@material-ui/core';
import { MoreVert, Visibility } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import { ButtonEnterprise, Card, PageTitle } from 'components';
import { useQueryString } from 'hooks';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import ReactModal from 'react-modal';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Popover, Whisper } from 'rsuite';
import { HubspotApi } from 'services/hubspot';
import { findAllLeads, updateLead } from 'services/leadsHubspot';
import { activeOfferLeadObservationSchema } from 'validations/activeOfferLeadObservation.schema';
import { emailSchema } from 'validations/email.schema';
import Container from './styles';

const ActiveOfferLeadsPage = ({ match }: RouteComponentProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [leadId, setLeadId] = useState(0);
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(activeOfferLeadObservationSchema),
  });
  const [leads, setLeads] = useState([]);
  const [lead, setLead] = useState<any>(null);
  const [count, setCount] = useState(0);
  const [totalLeads, setTotalLeads] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [myFilters, setMyFilters] = useState({
    page: String(currentPage),
    limit: String(10),
    leadStatus: 'todos',
    email: '',
    phone: '',
  });
  const filters = useQueryString(myFilters);

  const convertData = (data: string) => {
    return data.slice(0, 10).split('-').reverse().join('/');
  };

  const handleRedirectHubspot = (crmId: string) => {
    window.open(
      `https://app.hubspot.com/contacts/20455496/contact/${crmId}`,
      '_blank',
    );
  };

  const onInputChange = (value: string, key: string) => {
    setMyFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleFindAllLeads = async (queryString?: string) => {
    try {
      const { data } = await HubspotApi.findAcitveOfferLeads(queryString);
      setLeads(data.content);
      setTotalLeads(data.totalElements);
    } catch (error) {
      console.log(error);
    }
  };

  const sendActiveOfferLeadToUserPipeline = async (id: number) => {
    try {
      await HubspotApi.sendActiveOfferToUserPipeLine(id);
      await handleFindAllLeads(filters);
    } catch (error) {
      toast.error('Houve um erro ao enviar o lead para a sua pipeline');
    }
  };

  const handleRejectLead = async (values: any) => {
    try {
      const payload = {
        id: leadId,
        ...values,
      };

      await HubspotApi.rejectActiveOfferLead(payload);
      await handleFindAllLeads(filters);
    } catch (error) {
      toast.error(
        'Houve um erro ao registrar a tentativa de contato com o lead',
      );
    }

    setIsOpenModal(false);
    setLeadId(0);
  };

  // const handleKeyPress = (e: any, lead: any, email: any) => {
  //   if (e.key === 'Enter') {
  //     handleUpdateLead(lead.id, {
  //       ...lead,
  //       email,
  //     });
  //   }
  // };

  const handleGetLeadFromActiveOffer = async () => {
    try {
      await HubspotApi.getActiveOfferLead();
      await handleFindAllLeads();
    } catch (error) {
      toast.error(
        'Houve um erro ao associar um novo lead de oferta ativa ao seu usuário',
      );
    }
  };

  useEffect(() => {
    handleFindAllLeads(filters);
  }, [filters]);

  return (
    <Container>
      <ReactModal
        isOpen={lead}
        onRequestClose={() => {
          setLead(null);
        }}
        appElement={document.getElementById('root') || undefined}
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
      >
        <hr />
        {lead?.observations?.map((a: any) => (
          <div key={a.id}>
            <p>Observação feita por {a.user?.name}</p>
            <p>Data de criação: {new Date(a.createdAt).toLocaleDateString()}</p>
            <p>Observação: {a.text}</p>
            <hr />
          </div>
        ))}
      </ReactModal>

      <ReactModal
        isOpen={isOpenModal}
        onRequestClose={() => {
          setIsOpenModal(false);
          setLeadId(0);
        }}
        appElement={document.getElementById('root') || undefined}
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
      >
        <h2>Registrar Observações da Tentativa de Contato</h2>

        <hr />
        <br />
        <form onSubmit={handleSubmit(handleRejectLead)}>
          <TextField
            multiline
            style={{
              width: '100%',
            }}
            variant="outlined"
            label="Insira a observação"
            {...register('observation')}
          />
          <ButtonEnterprise type="submit">Registrar</ButtonEnterprise>
        </form>
      </ReactModal>
      <PageTitle title="Leads  da Oferta Ativa">
        <button type="button" onClick={handleGetLeadFromActiveOffer}>
          Buscar Novo Lead da Oferta Ativa
        </button>
      </PageTitle>
      <Toaster />
      {/* <section className="filters">
        <TextField
          className="filter-name"
          size="small"
          variant="outlined"
          label="Busca por nome"
          name="filter-name"
          defaultValue=""
          onChange={(e) => debouncedInputChangeHandler(e.target.value, 'name')}
        />
        <TextField
          className="filter-email"
          size="small"
          variant="outlined"
          label="Busca por e-mail"
          name="filter-email"
          defaultValue=""
          innerRef={emailRef}
          disabled={
            myFilters.leadStatus === '1' || myFilters.leadStatus === '2'
          }
          onChange={(e) => debouncedInputChangeHandler(e.target.value, 'email')}
        />

        <TextField
          className="filter-phone"
          size="small"
          variant="outlined"
          label="Busca por telefone"
          name="filter-phone"
          ref={phoneRef}
          defaultValue=""
          disabled={
            myFilters.leadStatus === '1' || myFilters.leadStatus === '3'
          }
          onChange={(e) => {
            debouncedInputChangeHandler(e.target.value, 'phone');
            phoneRef.current!.value = e.target.value || '';
          }}
        />
      </section> */}
      {leads.length ? (
        <Card className="list">
          <section className="results">
            <span>Resultados: {limit > totalLeads ? totalLeads : limit}/</span>
            {totalLeads}
          </section>

          <section className="columns-list item-row">
            <p>ID</p>
            <p>NOME</p>
            <p>TELEFONE</p>
            <p>E-MAIL</p>
            {/* <p>Entrada</p> */}
            <p>Empreendimento</p>
            <p>Status</p>
          </section>

          <section className="list-items">
            {leads.length > 0 &&
              leads.map((lead: any) => (
                <section key={lead.id} className="list-item item-row">
                  <p>{lead.id}</p>
                  <p>{lead.name}</p>
                  <p>{lead.phone}</p>
                  <p>{lead.email}</p>
                  {/* <p>{convertData(new Date().toLocaleDateString())}</p> */}
                  <p>
                    <a href={lead.enterpriseUrl ? lead.enterpriseUrl : '#'}>
                      {lead.enterpriseName}
                    </a>
                  </p>
                  <p>
                    {lead.status === 'QUALIFIED' ? 'Qualificado' : 'Aguardando'}
                  </p>
                  <Whisper
                    preventOverflow
                    enterable
                    trigger="click"
                    placement="right"
                    speaker={
                      <Popover
                        width="300px"
                        style={{
                          width: '240px',
                        }}
                      >
                        <div
                          className="popover-title"
                          style={{
                            width: '100%',
                          }}
                        >
                          <p>AÇÃO</p>
                        </div>
                        <hr />
                        <div className="action-button">
                          {lead.interations?.length >= 1 && (
                            <Link to={`/leads/${lead.id}`}>
                              <button className="edit-button" type="button">
                                <Visibility />
                                Ver {lead.interations?.length || 0} Interações
                              </button>
                            </Link>
                          )}
                          {lead.crmId && (
                            <button
                              className="edit-button"
                              onClick={() => handleRedirectHubspot(lead.crmId)}
                              type="button"
                            >
                              <Visibility />
                              visualizar lead no Hubspot
                            </button>
                          )}

                          <button
                            className="edit-button"
                            onClick={() => {
                              if (lead.observations.length === 0) {
                                toast.error('Lead não possui observações');
                                return;
                              }
                              setLead(lead);
                            }}
                            type="button"
                          >
                            <Visibility />
                            Visualizar Observações ({lead.observations.length})
                          </button>

                          {lead.status !== 'QUALIFIED' && (
                            <>
                              <button
                                className="edit-button"
                                onClick={() =>
                                  sendActiveOfferLeadToUserPipeline(lead.id)
                                }
                                type="button"
                              >
                                <Visibility />
                                Adicionar a minha pipeline
                              </button>
                              <button
                                className="edit-button"
                                onClick={() => {
                                  setIsOpenModal(true);
                                  setLeadId(lead.id);
                                }}
                                type="button"
                              >
                                <Visibility />
                                Registrar tentiva falha de contato
                              </button>
                            </>
                          )}
                        </div>
                      </Popover>
                    }
                  >
                    <MoreVert className="dots" />
                  </Whisper>
                </section>
              ))}
          </section>

          <section className="footer-list">
            <Pagination
              count={Math.round(totalLeads / 10) + 1}
              size="small"
              className="pagination"
              onChange={(_, page) => {
                setCurrentPage(page);
                setMyFilters((prevState) => ({
                  ...prevState,
                  page: String(page),
                  size: String(10),
                }));
              }}
            />
          </section>
        </Card>
      ) : (
        <p className="no-data">Sem registros disponíveis ou permitidos</p>
      )}
    </Container>
  );
};

export default withRouter(ActiveOfferLeadsPage);
