import styled from 'styled-components';

export const NavBarContainer = styled.nav`
  display: grid;
  padding: 0 5rem;
  > ul {
    border-bottom: 2px solid #eee;
    display: flex;

    li {
      cursor: pointer;
      font-size: 12px;
      padding: 0.5rem 1rem;
      margin-bottom: -2px;

      &.active {
        border-bottom: 2px solid blue;
      }
    }
  }
`;
