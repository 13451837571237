/* eslint-disable no-useless-escape */
import { TextField } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { MoreVert, Visibility } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import { Card, PageTitle, SelectStatus } from 'components';
import { useQueryString } from 'hooks';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Popover, Whisper } from 'rsuite';
import { findAllBrokers } from 'services/brokersHubspot';
import Container from './styles';

const BrokersPage = ({ match }: RouteComponentProps) => {
  const [realtorList, setRealtorList] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [brokers, setBrokers] = useState([]);
  const [count, setCount] = useState(0);
  const [totalLeads, setTotalLeads] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [myFilters, setMyFilters] = useState({
    page: String(currentPage),
    limit: String(10),
    name: '',
    cpf: '',
  });
  const filters = useQueryString(myFilters);

  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);

  //  TODO: implement util for date format
  const convertData = (data: string) => {
    return data.slice(0, 10).split('-').reverse().join('/');
  };

  const handleRedirectHubspot = (crmId: string) => {
    window.open(
      `https://app.hubspot.com/contacts/20455496/contact/${crmId}`,
      '_blank',
    );
  };

  const onInputChange = (value: string, key: string) => {
    setMyFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const debouncedInputChangeHandler = useMemo(
    () => debounce(onInputChange, 300),
    [],
  );

  const quantityItems = count >= 7 ? 7 : count;

  const handleFindAllBrokers = async (queryString?: string) => {
    try {
      const { data } = await findAllBrokers(queryString);
      setBrokers(data.brokers);
      setTotalLeads(data.total); //  data.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFindAllBrokers(filters);
  }, [filters]);

  return (
    <Container>
      <PageTitle
        title="Corretores Registrados na Base"
        buttonAction={() => setIsOpenModal(true)}
      />
      <Toaster />

      <section className="filters">
        <SelectStatus
          onChange={(crm) =>
            setMyFilters((prevState) => ({
              ...prevState,
              crm,
            }))
          }
        />
        {/* <Select
          label="Leads"
          name="leadStatus"
          onChange={(leadStatus) => {
            setMyFilters((prevState) => ({
              ...prevState,
              leadStatus,
              email:
                leadStatus === '1' || leadStatus === '2' ? '' : prevState.email,

              phone:
                leadStatus === '1' || leadStatus === '3' ? '' : prevState.phone,
            }));
            emailRef.current!.value =
              leadStatus === '1' || leadStatus === '2'
                ? ''
                : emailRef.current!.value;

            phoneRef.current!.value =
              leadStatus === '1' || leadStatus === '3'
                ? ''
                : emailRef.current!.value;
          }}
        >
          <MenuItem value="todos">Todos</MenuItem>
          <MenuItem value="1">Leads Incompletos</MenuItem>
          <MenuItem value="2">Leads Sem Email</MenuItem>
          <MenuItem value="3">Leads Sem Telefone</MenuItem>
        </Select> */}
        <TextField
          className="filter-name"
          size="small"
          variant="outlined"
          label="Busca por nome"
          name="filter-name"
          defaultValue=""
          onChange={(e) => debouncedInputChangeHandler(e.target.value, 'name')}
        />
        <TextField
          className="filter-email"
          size="small"
          variant="outlined"
          label="Busca por e-mail"
          name="filter-email"
          defaultValue=""
          innerRef={emailRef}
          onChange={(e) => debouncedInputChangeHandler(e.target.value, 'email')}
        />

        <TextField
          className="filter-phone"
          size="small"
          variant="outlined"
          label="Busca por telefone"
          name="filter-phone"
          ref={phoneRef}
          defaultValue=""
          onChange={(e) => {
            debouncedInputChangeHandler(e.target.value, 'phone');
            phoneRef.current!.value = e.target.value || '';
          }}
        />

        <TextField
          className="filter-phone"
          size="small"
          variant="outlined"
          label="Busca por CPF"
          name="filter-phone"
          ref={phoneRef}
          defaultValue=""
          onChange={(e) => {
            debouncedInputChangeHandler(e.target.value, 'cpf');
            phoneRef.current!.value = e.target.value || '';
          }}
        />

        <TextField
          className="filter-hubspot-id"
          size="small"
          variant="outlined"
          label="Busca por ID Hubspot"
          name="filter-phone"
          defaultValue=""
          onChange={(e) => debouncedInputChangeHandler(e.target.value, 'crmId')}
        />
      </section>

      {realtorList ? (
        <Card className="list">
          <section className="results">
            <span>Resultados: {limit > totalLeads ? totalLeads : limit}/</span>
            {totalLeads}
          </section>

          <section className="columns-list">
            <p>ID</p>
            <p>NOME</p>
            <p>ORIGEM</p>
            <p>TELEFONE</p>
            <p>E-MAIL</p>
            <p>Entrada</p>
            <p>Enviado Hubspot</p>
            <p>ID Hubspot</p>
          </section>

          <section className="list-items">
            {brokers?.length > 0 &&
              brokers?.map((broker: any) => (
                <section key={broker.id} className="list-item">
                  <p>{broker.id}</p>
                  <p>{broker.name}</p>
                  <p>{broker.origin}</p>
                  <p>{broker.phone}</p>
                  <p>{broker.email}</p>

                  <p>{convertData(broker.createdAt)}</p>
                  <p>
                    <Switch checked={broker.crm} disabled />
                  </p>
                  <p>{broker.crmId}</p>
                  <Whisper
                    preventOverflow
                    enterable
                    trigger="click"
                    placement="left"
                    speaker={
                      <Popover
                        width="300px"
                        style={{
                          width: '240px',
                        }}
                      >
                        <div
                          className="popover-title"
                          style={{
                            width: '100%',
                          }}
                        >
                          <p>AÇÃO</p>
                        </div>
                        <hr />
                        <div className="action-button">
                          {broker.crmId && (
                            <button
                              className="edit-button"
                              onClick={() =>
                                handleRedirectHubspot(broker.crmId)
                              }
                              type="button"
                            >
                              <Visibility />
                              visualizar broker no Hubspot
                            </button>
                          )}
                        </div>
                      </Popover>
                    }
                  >
                    <MoreVert className="dots" />
                  </Whisper>
                </section>
              ))}
          </section>

          <section className="footer-list">
            <Pagination
              count={Math.round(totalLeads / 10) + 1}
              size="small"
              className="pagination"
              onChange={(_, page) => {
                setCurrentPage(page);
                setMyFilters((prevState) => ({
                  ...prevState,
                  page: String(page),
                }));
              }}
            />
          </section>
        </Card>
      ) : (
        <p className="no-data">Sem registros disponíveis ou permitidos</p>
      )}
    </Container>
  );
};

export default withRouter(BrokersPage);
