/* eslint-disable react/prop-types */
import { MenuItem, TextField } from '@material-ui/core';
import React from 'react';

// import { Container } from './styles';
type Props = {
  onChange: (value: string) => void;
};

const SelectStatus: React.FC<Props> = ({ onChange }) => {
  return (
    <TextField
      select
      size="small"
      variant="outlined"
      className="filter-status"
      label="Status Hubspot"
      name="filter-status"
      defaultValue="todos"
      onChange={(e) =>
        onChange(e.currentTarget.getAttribute('data-value') || '')
      }
    >
      <MenuItem key="todos" value="todos">
        Todos
      </MenuItem>
      <MenuItem key="ativo" value="true">
        Enviados Hubspot
      </MenuItem>
      <MenuItem key="inativo" value="false">
        Pendente Hubspot
      </MenuItem>
    </TextField>
  );
};

export { SelectStatus };
