export const formatPrice = (price: number): string => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(price);
};

export const toLocateDate = (date: string | Date): string =>
  new Date(date).toLocaleDateString('pt-BR', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

export const calculateVariation = (
  current: number,
  previous: number,
): string => {
  if (!previous) {
    return '0.00';
  }

  return (((current - previous) / previous) * 100).toFixed(2);
};
