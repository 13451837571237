import styled from 'styled-components';

type ContainerProps = {
  isLoading?: boolean;
};

export const Container = styled.section<ContainerProps>`
  filter: ${({ isLoading }) => isLoading && `blur(0.1rem) grayscale(80%);`};
  pointer-events: ${({ isLoading }) => isLoading && 'none'};
`;

export const PartnersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 175px) 100px auto;
  grid-gap: 10px;
  align-items: center;
`;
