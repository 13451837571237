/* eslint-disable no-useless-escape */
import { MenuItem, TextField } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { MoreVert, Visibility } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import {
  Card,
  PageTitle,
  SelectOrigin,
  SelectStatus,
  SelectType,
} from 'components';
import { Select } from 'components/Select';
import { useQueryString } from 'hooks';
import { debounce, update } from 'lodash';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Toaster } from 'react-hot-toast';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Popover, Whisper } from 'rsuite';
import { findAllLeads, updateLead } from 'services/leadsHubspot';
import { emailSchema } from 'validations/email.schema';
import { LeadModal } from './LeadModal';
import Container from './styles';

const RealtorPage = ({ match }: RouteComponentProps) => {
  const [realtorList, setRealtorList] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [leads, setLeads] = useState([]);
  const [count, setCount] = useState(0);
  const [totalLeads, setTotalLeads] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [myFilters, setMyFilters] = useState({
    page: String(currentPage),
    limit: String(10),
    leadStatus: 'todos',
    email: '',
    phone: '',
  });
  const [loggedEmail, setLoggedEmail] = useState<string | null>(null);
  const filters = useQueryString(myFilters);

  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const email = localStorage.getItem('@Auth:email');

    if (email) {
      setLoggedEmail(email);
    }
  }, []);

  //  TODO: implement util for date format
  const convertData = (data: string) => {
    return data.slice(0, 10).split('-').reverse().join('/');
  };

  const handleRedirectHubspot = (crmId: string) => {
    window.open(
      `https://app.hubspot.com/contacts/20455496/contact/${crmId}`,
      '_blank',
    );
  };

  const onInputChange = (value: string, key: string) => {
    setMyFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const debouncedInputChangeHandler = useMemo(
    () => debounce(onInputChange, 300),
    [],
  );

  const quantityItems = count >= 7 ? 7 : count;

  const handleUpdateLead = async (id: number, payload: any) => {
    try {
      await emailSchema.validate(payload.email);
      await updateLead(id, payload);
    } catch (error) {
      console.log(error);
    } finally {
      await handleFindAllLeads(filters);
    }
  };

  const handleFindAllLeads = async (queryString?: string) => {
    try {
      const { data } = await findAllLeads(queryString);
      setLeads(data.leads);
      setTotalLeads(data.total); //  data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConvertTypeLead = (type?: 'CUSTOMER' | 'BROKER' | '') => {
    switch (type) {
      case 'CUSTOMER':
        return 'Lead';
        break;
      case 'BROKER':
        return 'Corretor';
        break;
      default:
        return '';
        break;
    }
  };

  const handleKeyPress = (e: any, lead: any, email: any) => {
    if (e.key === 'Enter') {
      handleUpdateLead(lead.id, {
        ...lead,
        email,
      });
    }
  };

  useEffect(() => {
    handleFindAllLeads(filters);
  }, [filters]);

  return (
    <Container>
      <PageTitle title="Leads Registrados na Base">
        <button type="button" onClick={() => setIsOpenModal(true)}>
          Cadastrar Lead
        </button>
      </PageTitle>
      {isOpenModal && (
        <LeadModal
          isModalOpen={isOpenModal}
          onCloseModal={() => {
            setIsOpenModal(false);
            handleFindAllLeads(filters);
          }}
        />
      )}
      <Toaster />

      <section className="filters">
        <SelectOrigin
          onChange={(origin) =>
            setMyFilters((prevState) => ({
              ...prevState,
              origin,
            }))
          }
        />

        <SelectStatus
          onChange={(crm) =>
            setMyFilters((prevState) => ({
              ...prevState,
              crm,
            }))
          }
        />
        <SelectType
          onChange={(type) =>
            setMyFilters((prevState) => ({
              ...prevState,
              type,
            }))
          }
        />
        <Select
          label="Leads"
          name="leadStatus"
          onChange={(leadStatus) => {
            setMyFilters((prevState) => ({
              ...prevState,
              leadStatus,
              email:
                leadStatus === '1' || leadStatus === '2' ? '' : prevState.email,

              phone:
                leadStatus === '1' || leadStatus === '3' ? '' : prevState.phone,
            }));
            emailRef.current!.value =
              leadStatus === '1' || leadStatus === '2'
                ? ''
                : emailRef.current!.value;

            phoneRef.current!.value =
              leadStatus === '1' || leadStatus === '3'
                ? ''
                : emailRef.current!.value;
          }}
        >
          <MenuItem value="todos">Todos</MenuItem>
          <MenuItem value="1">Leads Incompletos</MenuItem>
          <MenuItem value="2">Leads Sem Email</MenuItem>
          <MenuItem value="3">Leads Sem Telefone</MenuItem>
        </Select>
        <TextField
          className="filter-name"
          size="small"
          variant="outlined"
          label="Busca por nome"
          name="filter-name"
          defaultValue=""
          onChange={(e) => debouncedInputChangeHandler(e.target.value, 'name')}
        />
        <TextField
          className="filter-email"
          size="small"
          variant="outlined"
          label="Busca por e-mail"
          name="filter-email"
          defaultValue=""
          innerRef={emailRef}
          disabled={
            myFilters.leadStatus === '1' || myFilters.leadStatus === '2'
          }
          onChange={(e) => debouncedInputChangeHandler(e.target.value, 'email')}
        />

        <TextField
          className="filter-phone"
          size="small"
          variant="outlined"
          label="Busca por telefone"
          name="filter-phone"
          ref={phoneRef}
          defaultValue=""
          disabled={
            myFilters.leadStatus === '1' || myFilters.leadStatus === '3'
          }
          onChange={(e) => {
            debouncedInputChangeHandler(e.target.value, 'phone');
            phoneRef.current!.value = e.target.value || '';
          }}
        />

        <TextField
          className="filter-hubspot-id"
          size="small"
          variant="outlined"
          label="Busca por ID  Hubspot"
          name="filter-phone"
          defaultValue=""
          onChange={(e) => debouncedInputChangeHandler(e.target.value, 'crmId')}
        />
      </section>

      {realtorList ? (
        <Card className="list">
          <section className="results">
            <span>Resultados: {limit > totalLeads ? totalLeads : limit}/</span>
            {totalLeads}
          </section>

          <section className="columns-list">
            <p>id</p>
            <p>Nome</p>
            <p>Origem</p>
            <p>Telefone</p>
            <p>E-mail</p>
            <p>Entrada</p>
            <p>Tipo Lead</p>
            <p>Hubspot</p>
            <p>Ações</p>
          </section>

          <section className="list-items">
            {leads.length > 0 &&
              leads.map((lead: any) => (
                <section key={lead.id} className="list-item">
                  <p>{lead.id}</p>
                  <p>{lead.name}</p>
                  <p>{lead.origin}</p>
                  <p>{lead.phone}</p>
                  {lead.email ? (
                    <span title={lead.email}>{lead.email}</span>
                  ) : (
                    <input
                      onBlur={(e) =>
                        handleUpdateLead(lead.id, {
                          ...lead,
                          email: e.target.value,
                        })
                      }
                      onKeyUp={(e) =>
                        handleKeyPress(e, lead, e.currentTarget.value)
                      }
                    />
                  )}

                  <p>{convertData(lead.createdAt)}</p>
                  <p>{handleConvertTypeLead(lead.type)}</p>
                  <p>
                    <Switch checked={lead.crm} disabled />
                  </p>
                  <Whisper
                    preventOverflow
                    enterable
                    trigger="click"
                    placement="left"
                    speaker={
                      <Popover
                        width="300px"
                        style={{
                          width: '240px',
                        }}
                      >
                        <div
                          className="popover-title"
                          style={{
                            width: '100%',
                          }}
                        >
                          <p>AÇÃO</p>
                        </div>
                        <hr />
                        <div className="action-button">
                          {lead.interations?.length >= 1 && (
                            <Link to={`/leads/${lead.id}`}>
                              <button className="edit-button" type="button">
                                <Visibility />
                                Ver {lead.interations?.length || 0} Interações
                              </button>
                            </Link>
                          )}
                          {lead.crmId && (
                            <button
                              className="edit-button"
                              onClick={() => handleRedirectHubspot(lead.crmId)}
                              type="button"
                            >
                              <Visibility />
                              visualizar lead no Hubspot
                            </button>
                          )}
                        </div>
                      </Popover>
                    }
                  >
                    <MoreVert className="dots" />
                  </Whisper>
                </section>
              ))}
          </section>

          <section className="footer-list">
            <Pagination
              count={Math.round(totalLeads / 10) + 1}
              size="small"
              className="pagination"
              onChange={(_, page) => {
                setCurrentPage(page);
                setMyFilters((prevState) => ({
                  ...prevState,
                  page: String(page),
                }));
              }}
            />
          </section>
        </Card>
      ) : (
        <p className="no-data">Sem registros disponíveis ou permitidos</p>
      )}
    </Container>
  );
};

export default withRouter(RealtorPage);
