import axios from 'axios';
import { IPaginatedResponse } from 'typings/pagiant';
import { IFeature, IUser } from './type';

const index = (qs?: string) =>
  axios.get<IPaginatedResponse<IUser>>(`/users?${qs}`);

const find = (id: string) => axios.get<IUser>(`/users/${id}`);

const features = () => axios.get<IFeature[]>(`/users/features`);

const create = (payload: any) => axios.post('/users', payload);

const update = (id: string, payload: any) => axios.put(`/users/${id}`, payload);

const forceReset = (id: string) => axios.patch(`/users/${id}/force-reset`);

export const UserApi = {
  index,
  features,
  create,
  find,
  update,
  forceReset,
};
