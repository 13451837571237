import { Menu } from 'components';
import Header from 'components/Header';
import AuthContext from 'contexts/Auth';
import { EnterpriseProvider } from 'contexts/Enterprises';
import RegisterPassword from 'pages/cadastrar-senha';
import Dashboard from 'pages/dashboard';
import EnterprisesPage from 'pages/enterprises';
import EnterpriseAptoCSVPage from 'pages/enterprises-aptovc-csv';
import LeadInterations from 'pages/lead-interations';
import BrokersPage from 'pages/brokers';
import RealtorPage from 'pages/leads';
import EnterprisesZapXmlPage from 'pages/enterprises-zap-xml';
import UserListPage from 'pages/users';
import UserCreatePage from 'pages/users-create';
import ProfilePage from 'pages/profile';
import Login from 'pages/login';
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import HubspotUsersPage from 'pages/hubspot-users';
import HubspotUsersEditPage from 'pages/hubspot-users-create';
import UpdateNewEnterprise from 'pages/newEnterprises/UpdateNewEnterprise';
import EnterpriseAdminRealtor from 'pages/enterprises-admin-corretor-csv';
import NewEnterprisePage from 'pages/newEnterprises';
import activerOfferLeads from 'pages/activer-offer-leads';
import { LoggedIn, NotLoggedIn } from './styles';

const Routes: React.FC = () => {
  const { signed } = useContext(AuthContext);

  if (signed) {
    return (
      <LoggedIn>
        <header>
          <Header />
        </header>
        <aside>
          <Menu />
        </aside>
        <main>
          <Switch>
            <EnterpriseProvider>
              <Route exact path="/leads" component={RealtorPage} />
              <Route
                exact
                path="/activeOfferLeads"
                component={activerOfferLeads}
              />
              <Route exact path="/leads/:id" component={LeadInterations} />
              <Route exact path="/enterprises" component={EnterprisesPage} />
              <Route
                exact
                path="/newEnterprises"
                component={NewEnterprisePage}
              />
              <Route
                exact
                path="/newEnterprises/UpdateNewEnterprise/:id"
                component={UpdateNewEnterprise}
              />
              <Route
                exact
                path="/enterprises/admin/portal/relatory"
                component={EnterpriseAdminRealtor}
              />
              <Route
                exact
                path="/enterprises/aptovc/csv"
                component={EnterpriseAptoCSVPage}
              />
              <Route exact path="/users" component={UserListPage} />
              <Route exact path="/users/create" component={UserCreatePage} />
              <Route
                exact
                path="/users/create/:id"
                component={UserCreatePage}
              />
              <Route exact path="/hubspot/users" component={HubspotUsersPage} />
              <Route
                exact
                path="/hubspot/users/:id"
                component={HubspotUsersEditPage}
              />
              <Route exact path="/profile" component={ProfilePage} />
              <Route
                exact
                path="/enterprises/grupozap/xml"
                component={EnterprisesZapXmlPage}
              />

              <Route exact path="/brokers" component={BrokersPage} />

              <Route exact path="/" component={Dashboard} />
            </EnterpriseProvider>
          </Switch>
        </main>
      </LoggedIn>
    );
  }

  if (!signed) {
    return (
      <NotLoggedIn>
        <Switch>
          <Route
            path="/cadastrar-senha/:params?"
            component={RegisterPassword}
          />
          <Route path="/login" component={Login} />
        </Switch>
      </NotLoggedIn>
    );
  }

  return null;
};

export default Routes;
