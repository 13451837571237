import styled from 'styled-components';

export const LoggedIn = styled.section`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 70px 1fr;
  height: 100vh;

  > header {
    grid-column: 1/3;
  }

  > aside {
    display: grid;
  }

  > main {
    height: 100%;
    overflow-y: scroll;
    overflow-x: none;
  }
`;

export const NotLoggedIn = styled.section``;
