/* eslint-disable react/jsx-props-no-spreading */
import { useEnterpriseContext } from 'contexts/Enterprises';
import React from 'react';
import ReactModal from 'react-modal';
import { EnterpriseForm } from '../form';

type Props = {
  isModalOpen: boolean;
  onCloseModal: () => void;
};

const UpdateEnterpriseModal: React.FC<Props> = ({
  isModalOpen,
  onCloseModal,
}) => {
  const { handleUpdateEnterprise, enterprise } = useEnterpriseContext();

  const onSubmit = async (data: any) => {
    await handleUpdateEnterprise(data);
    onCloseModal();
  };

  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={onCloseModal}
      appElement={document.getElementById('root') || undefined}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      style={{
        content: {
          overflow: 'scroll',
          height: '100%',
        },
      }}
    >
      <div>
        <h1>Atualizar empreendimento</h1>

        <EnterpriseForm
          defaultValues={enterprise}
          onFormSubmit={onSubmit}
          buttonTitle="Atualizar Empreendimento"
        />
      </div>
    </ReactModal>
  );
};

UpdateEnterpriseModal.defaultProps = {
  isModalOpen: undefined,
  onCloseModal: undefined,
};

export { UpdateEnterpriseModal };
