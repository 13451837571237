import React, { useEffect, useState } from 'react';
import { Container, Wrapper } from './styles';

type Props = {
  firstCard: JSX.Element;
  firstCardProps: {
    title: string;
    object: any;
  };
  secondCard: JSX.Element;
  secondCardProps: {
    title: string;
    object: any;
  };
  onSelect: (value: any) => void;
};

export const ComparativeCard: React.FC<Props> = ({
  firstCard,
  firstCardProps,
  secondCard,
  secondCardProps,
  onSelect,
}) => {
  const [selectedCard, setSelectedCard] = useState<number>();

  const selectedObject = {
    FIRST: 1,
    SECOND: 2,
  };

  const generateMessage = () => {
    switch (selectedCard) {
      case selectedObject.FIRST:
        return firstCardProps.title;
      case selectedObject.SECOND:
        return secondCardProps.title;
      default:
        return '';
    }
  };

  const generateObject = () => {
    switch (selectedCard) {
      case selectedObject.FIRST:
        return firstCardProps.object;
      case selectedObject.SECOND:
        return secondCardProps.object;
      default:
        return undefined;
    }
  };

  const handleSelect = (value: any) => {
    setSelectedCard(value);
  };

  useEffect(() => {
    if (!selectedCard) {
      return;
    }

    onSelect(generateObject());
  }, [selectedCard]);

  return (
    <>
      <Container>
        <Wrapper
          isActive={selectedCard === selectedObject.FIRST}
          onClick={() => handleSelect(selectedObject.FIRST)}
        >
          {firstCard}
        </Wrapper>
        <Wrapper
          isActive={selectedCard === selectedObject.SECOND}
          onClick={() => handleSelect(selectedObject.SECOND)}
        >
          {secondCard}
        </Wrapper>
      </Container>

      {generateMessage() && <h4>Você selecionou: {generateMessage()}</h4>}
    </>
  );
};
