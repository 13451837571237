/* eslint-disable no-useless-escape */
import { TextField } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Pagination from '@material-ui/lab/Pagination';
import { Card, PageTitle, SelectOrigin, SelectStatus } from 'components';
import { useQueryString } from 'hooks';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { findAllLeads } from 'services/leadsHubspot';
import Container from './styles';

interface IQueryString {
  id: string;
  origin?: string;
  crm?: string;
  name?: string;
  email?: string;
  phone?: string;
}

const RealtorPage = () => {
  const { id } = useParams<{ id: string }>();
  const [realtorList, setRealtorList] = useState([]);
  const [leads, setLeads] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(8);

  const [myFilters, setMyFilters] = useState<IQueryString>({
    id,
  });

  const filters = useQueryString(myFilters);

  //  TODO: implement util for date format
  const convertData = (data: string) => {
    return data.slice(0, 10).split('-').reverse().join('/');
  };

  const onInputChange = (value: string, key: string) => {
    setMyFilters({
      ...myFilters,
      [key]: value,
    });
  };

  const debouncedInputChangeHandler = useMemo(
    () => debounce(onInputChange, 300),
    [],
  );

  const quantityItems = count >= 7 ? 8 : count;

  const addPhoneMask = (phone: string) => {
    const phoneNumber = /^(\d{2})(\d{5})(\d{4})$/g;
    return phone?.replace(phoneNumber, '($1) $2-$3');
  };

  useEffect(() => {
    async function handleFindAllLeads(queryString: string) {
      try {
        const { data } = await findAllLeads(queryString);
        const { interations } = data?.leads[0];
        setLeads(interations);
        setCount(interations.length);
      } catch (error) {
        console.log(error);
      }
    }

    handleFindAllLeads(filters);
  }, [filters]);

  return (
    <Container>
      <PageTitle title="122" />
      <section className="filters">
        <SelectOrigin
          onChange={(origin) =>
            setMyFilters({
              ...myFilters,
              origin,
            })
          }
        />

        <SelectStatus
          onChange={(crm) =>
            setMyFilters({
              ...myFilters,
              crm,
            })
          }
        />
        <TextField
          className="filter-name"
          size="small"
          variant="outlined"
          label="Busca por nome"
          name="filter-name"
          defaultValue=""
          onChange={(e) => debouncedInputChangeHandler(e.target.value, 'name')}
        />
        <TextField
          className="filter-email"
          size="small"
          variant="outlined"
          label="Busca por e-mail"
          name="filter-email"
          defaultValue=""
          onChange={(e) => debouncedInputChangeHandler(e.target.value, 'email')}
        />

        <TextField
          className="filter-phone"
          size="small"
          variant="outlined"
          label="Busca por telefone"
          name="filter-phone"
          defaultValue=""
          onChange={(e) => debouncedInputChangeHandler(e.target.value, 'phone')}
        />
      </section>

      {realtorList ? (
        <Card className="list">
          <section className="results">
            <span>Resultados: {quantityItems}/</span>
            {count}
          </section>

          <section className="columns-list">
            <p>ID</p>
            <p>NOME</p>
            <p>ORIGEM</p>
            <p>TELEFONE</p>
            <p>E-MAIL</p>
            <p>Entrada</p>
            <p>No CRM</p>
          </section>

          <section className="list-items">
            {leads.length > 0 &&
              leads.map((lead: any) => (
                <section key={lead.id} className="list-item">
                  <p>{lead.id}</p>
                  <p>{lead.name}</p>
                  <p>{lead.origin}</p>
                  <p>{addPhoneMask(lead.phone)}</p>
                  <span title={lead.email}>{lead.email}</span>
                  <p>{convertData(lead.createdAt)}</p>
                  <p>
                    <Switch checked={lead.crm} disabled />
                  </p>
                </section>
              ))}
          </section>

          <section className="footer-list">
            <Pagination
              count={Math.round(count / limit)}
              size="small"
              className="pagination"
              onChange={(_, page) => setCurrentPage(page)}
            />
          </section>
        </Card>
      ) : (
        <p className="no-data">Sem registros disponíveis ou permitidos</p>
      )}
    </Container>
  );
};

export default withRouter(RealtorPage);
