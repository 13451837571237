/* eslint-disable jsx-a11y/control-has-associated-label */
import { MenuItem, TextField } from '@material-ui/core';
import { LoadingContainer } from 'components/LoadingContainer/styles';
import { useQueryString } from 'hooks';
import React, { useEffect, useState } from 'react';
import { loadDashboardData } from 'services/dashboard';
import Container from './styles';

interface Info {
  portal: string;
  leadsTotal: number;
  leadsFailed: number;
  createdAt: Date;
  updatedAt: Date;
}

interface IResponse {
  infos: Info[];
  total: number;
  failed: number;
  incomplete: number;
  leadWithoutEnterpriseData: number;
}

const origins = [
  { id: 1, key: 'AoCubo', value: 'aoCubo' },
  { id: 2, key: 'VivaReal', value: 'vivareal' },
  { id: 3, key: 'Zap', value: 'zap' },
  { id: 4, key: 'Mercado Livre', value: 'mercadoLivre' },
  { id: 5, key: 'OLX', value: 'olx' },
  { id: 6, key: 'Cv', value: 'cv' },
  { id: 7, key: '123i', value: '123i' },
  { id: 8, key: 'Apto.vc', value: 'apto.vc' },
  { id: 9, key: 'AppMeuImovel', value: 'AppMeuImovel' },
  { id: 10, key: 'Yellow Pepper', value: 'Yellow Pepper' },
];

const Dashboard = () => {
  const [status, setStatus] = useState<string | undefined>('');
  const [dateFilters, setDateFilters] = useState({
    finalDate: '',
    startDate: '',
    origin: 'todos',
  });
  const [dashboard, setDashboard] = useState({} as IResponse);

  const [isLoading, setIsLoading] = useState(false);
  const isValideFilters = Object.keys(dateFilters).length > 0;
  const queryString = useQueryString(dateFilters);

  useEffect(() => {
    handleLoadDashboard();
  }, []);

  async function handleLoadDashboard(qs?: string) {
    try {
      setIsLoading(true);
      const { data } = await loadDashboardData(qs);
      setDashboard(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleStatus = (value: string | undefined) => {
    setStatus(value);
  };

  return (
    <Container>
      <div className="teste">
        <div
          style={{
            display: 'flex',
            gap: 10,
          }}
        >
          <TextField
            type="date"
            variant="outlined"
            className="date"
            value={dateFilters.startDate}
            onChange={(e) =>
              setDateFilters((prevState) => ({
                ...prevState,
                startDate: e.target.value,
              }))
            }
          />
          <TextField
            type="date"
            variant="outlined"
            className="date"
            value={dateFilters.finalDate}
            onChange={(e) =>
              setDateFilters((prevState) => ({
                ...prevState,
                finalDate: e.target.value,
              }))
            }
          />
          <TextField
            variant="outlined"
            className="date"
            value={dateFilters.origin}
            onChange={(e) =>
              setDateFilters((prevState) => ({
                ...prevState,
                origin: e.target.value,
              }))
            }
            select
            defaultValue="todos"
          >
            <MenuItem value="todos">Todos</MenuItem>
            {origins.map((origin) => (
              <MenuItem value={origin.key} key={origin.key}>
                {origin.key}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="buttonContainer">
          <button
            className="apply-button"
            type="button"
            disabled={!isValideFilters}
            onClick={() => handleLoadDashboard(queryString)}
          >
            Aplicar
          </button>

          <button
            className="apply-button"
            type="button"
            disabled={!isValideFilters}
            onClick={() => {
              handleLoadDashboard();
              setDateFilters({
                finalDate: '',
                startDate: '',
                origin: 'todos',
              });
            }}
          >
            Limpar
          </button>
        </div>
      </div>

      <section className="page-content">
        <LoadingContainer isLoading={isLoading}>
          <div className="grid-container">
            <div className="items">
              <h1 className="total">{dashboard.total || 0}</h1>
              <p className="subtitle">Leads Recebidos</p>
            </div>
            <div className="items">
              <h1 className="total">{dashboard.incomplete || 0}</h1>
              <p className="subtitle">Leads Incompletos</p>
            </div>
            <div className="items">
              <h1 className="total">{dashboard.failed || 0}</h1>
              <p className="subtitle">Leads Com Erros</p>
            </div>
            <div className="items">
              <h1 className="total">
                {dashboard.leadWithoutEnterpriseData || 0}
              </h1>
              <p className="subtitle">Leads Sem Dados de Empreendimentos</p>
            </div>
            {dashboard.infos?.map((item: any) => (
              <div key={item.portal} className="items">
                <h1 className="total">{item.leadsTotal}</h1>
                <p className="subtitle">Portal: {item.portal}</p>
              </div>
            ))}
          </div>
        </LoadingContainer>
      </section>

      {/* <aside>
        <h1 className="title">FILTROS</h1>

        
      </aside> */}
    </Container>
  );
};

export default Dashboard;
