/* eslint-disable no-useless-escape */
import {
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Button,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Box,
  Chip,
  Typography,
  Switch,
} from '@material-ui/core';
import { ButtonEnterprise, PageTitle } from 'components';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { withRouter } from 'react-router-dom';
import { JobRolesEnum } from 'utils/jobRolesEnum';
import { translateFeatures, translateJobRolesEnum } from 'utils/translateEnum';
import { useHistory, useParams } from 'react-router';
import { UserApi } from 'services/users';
import { IFeature, IUser } from 'services/users/type';

import { userSchema } from 'validations/user.schema';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container } from './styles';

const UsersCreatePage = () => {
  const [roles, setRoles] = useState<string[]>([]);
  const history = useHistory();
  const jobRoles = Object.keys(JobRolesEnum) as JobRolesEnum[];
  const [features, setFeatures] = useState<IFeature[]>([]);
  const [selectedFeatures, setSelectedFeatures] = useState<any[]>([]);
  const { id } = useParams<{ id: string }>();

  const { register, handleSubmit, reset, watch, setValue, control } = useForm({
    resolver: yupResolver(userSchema),
  });

  const handleSelectFeatures = (feature: any) => {
    const alreadyInList = selectedFeatures.find(
      (selectedFeature) => selectedFeature.name === feature.name,
    );

    if (alreadyInList) {
      const arrayWithoutFeature = selectedFeatures.filter(
        (selectedFeature) => selectedFeature.name !== feature.name,
      );
      setSelectedFeatures(arrayWithoutFeature);

      return;
    }

    setSelectedFeatures([...selectedFeatures, feature]);
  };

  const onSubmit = async (user: any) => {
    try {
      const featuresIds = selectedFeatures.map(
        (selectedFeature) => selectedFeature.id,
      );
      const payload = { ...user, featuresIds };

      const data = id
        ? await UserApi.update(id, payload)
        : await UserApi.create(payload);

      const message = id ? `Usuario Atualizado` : `Usuario Cadastrado`;

      toast.success(message);
      history.push('/users');
    } catch (error) {
      toast.error('Nao foi possivel cadastrar o usuario');
    }
  };

  const handleFindFeatures = async () => {
    try {
      const { data } = await UserApi.features();
      setFeatures(data.filter((feature) => feature.name !== 'ADMIN'));
    } catch (error) {
      toast.error(`Nao foi possivel carregar a lista de acessos`);
    }
  };

  const populateForm = (user: IUser) => {
    Object.entries(user).forEach(([key, value]) => {
      setValue(key, value);
    });
    setSelectedFeatures(user.features);
  };

  const handleFindUser = async (userId: string) => {
    try {
      const { data } = await UserApi.find(userId);
      populateForm(data);
    } catch (error) {
      toast.error(`Nao foi possivel encontrar o usuario`);
    }
  };

  useEffect(() => {
    handleFindFeatures();

    if (id) {
      handleFindUser(id);
    }
  }, []);

  return (
    <Container>
      <PageTitle title="Usuarios do AocuboCRM">
        <button type="button" onClick={() => history.push('/users')}>
          Voltar
        </button>
      </PageTitle>

      <Toaster />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControlLabel
          key={watch('isActive')}
          control={
            <Controller
              name="isActive"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Switch checked={value} onChange={onChange} value={value} />
              )}
            />
          }
          label="Usuario Ativo"
        />
        {watch('isActive')}
        <Grid container direction="column" xs={5}>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Nome do Usuario"
                size="small"
                variant="outlined"
              />
            )}
          />

          <TextField
            size="small"
            variant="outlined"
            margin="normal"
            label="Email"
            InputLabelProps={{
              shrink: true,
            }}
            {...register('email')}
          />

          <Controller
            name="jobRole"
            control={control}
            key={Math.random()}
            render={({ field: { onChange, value } }) => (
              <TextField
                select
                size="small"
                variant="outlined"
                margin="normal"
                label="Cargo"
                onChange={onChange}
                value={value}
                key={jobRoles.length}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {jobRoles.map((jobRole) => (
                  <MenuItem key={`${jobRole}`} value={jobRole}>
                    {translateJobRolesEnum(jobRole)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Typography>Tem acesso a:</Typography>
          {features.map((feature) => (
            <FormControlLabel
              {...register('features')}
              key={feature.name}
              value={selectedFeatures}
              label={translateFeatures(feature.name)}
              control={
                <Checkbox
                  checked={selectedFeatures.some((selectedFeature) =>
                    selectedFeature?.name?.includes(feature.name),
                  )}
                  onChange={() => handleSelectFeatures(feature)}
                />
              }
            />
          ))}
        </Grid>

        <ButtonEnterprise type="submit">
          {id ? `Atualizar` : `Cadastrar`}
        </ButtonEnterprise>
      </form>
    </Container>
  );
};

export default withRouter(UsersCreatePage);
