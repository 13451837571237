import styled from 'styled-components';

const ButtonEnterprise = styled.button`
  color: #ffffff;
  font-family: Inter;
  font-size: 14px;
  margin-top: 2rem;
  font-weight: 400;
  background: #002180;
  padding: 11px 67px;
  border-radius: 0.5rem;
  transition: filter ease 0.2s;

  &:hover {
    filter: brightness(1.2);
  }
`;

export default ButtonEnterprise;
