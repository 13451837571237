/* eslint-disable @typescript-eslint/no-unused-vars */
import AuthContext from 'contexts/Auth';
import React, { useContext } from 'react';
import { InputPassword, Input } from '../../components';
import { Container } from './style';

const Login: React.FC = () => {
  const { signIn } = useContext(AuthContext);

  function handleSignIn(event: any) {
    event.preventDefault();

    const formData = new FormData(event.target);
    const email = formData.get('email');
    const password = formData.get('password');

    signIn({ email, password });
  }

  return (
    <Container>
      <div className="background-ilustrative">
        <div className="logo">
          <img src="/logo-aocubo-large.png" alt="aoCubo" />
        </div>

        <div className="form-container">
          <h2>Entrar</h2>
          <p>Que bom ter você aqui novamente!</p>
          <form onSubmit={handleSignIn}>
            <Input label="Email" name="email" dataTestId="email-login" />
            <InputPassword />
            <button type="submit" className="btn-form">
              Entrar
            </button>
          </form>
        </div>
      </div>
      <div className="img" />
    </Container>
  );
};

export default Login;
