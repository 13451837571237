import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { EnterprisesApi } from 'services/enterprises/index';
import { useEnterpriseContext } from 'contexts/Enterprises';
import {
  BackButton,
  NewEnterpriseContainer,
  NewEnterpriseTitle,
} from './styles';
import EnterpriseForm from './Form';

type AnnouncementsType = {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null | string;
  portal: string;
  integrationId: string;
  isActive: boolean;
  announcementUrl: string;
  photoUrl: string;
};

type EnterpriseType = {
  id: number;
  type: string;
  integrationId: number;
  isActive: boolean;
  name: string;
  price: number;
  address: {
    city: string;
    region: string;
  };
  announcements: AnnouncementsType[];
};

const UpdateNewEnterprise: React.FC = () => {
  const params = useParams<{ id: string }>();

  const history = useHistory();

  const [enterprise, setEnterprise] = useState<EnterpriseType>();

  const { zones } = useEnterpriseContext();

  const getEnterprise = async () => {
    const response = await EnterprisesApi.find(Number(params.id));
    setEnterprise(await response.data);
  };
  useEffect(() => {
    getEnterprise();
  }, []);

  return (
    <NewEnterpriseContainer>
      <BackButton type="button" onClick={() => history.push('/newEnterprises')}>
        « Voltar
      </BackButton>
      <NewEnterpriseTitle>Atualizar cadastro</NewEnterpriseTitle>
      {enterprise ? (
        <EnterpriseForm enterpriseData={enterprise} zones={zones} />
      ) : (
        <div>Loading...</div>
      )}
    </NewEnterpriseContainer>
  );
};

export default UpdateNewEnterprise;
