import axios from 'axios';

export interface IXMLRelatory {
  id: number;
  filename: string;
  totalSent: number;
  totalAnnouncements: number;
  lackInfo: number;
  economic: number;
  highStandard: number;
  mediumStandard: number;
  isActive: boolean;
  createdAt: Date;
}

type FindAllXMLResponse = {
  xmlRelatorys: IXMLRelatory[];
  total: number;
};

const findAllXMLRelatorys = (qs?: string) =>
  axios.get<FindAllXMLResponse>(`/xml/grupozap?${qs}`);

const findActiveXMLRelatory = () =>
  axios.get<IXMLRelatory>('/xml/grupozap/active');

const enableDisableXMLRelatory = (id: number) =>
  axios.patch(`/xml/grupozap/${id}/active`);

export { findAllXMLRelatorys, enableDisableXMLRelatory, findActiveXMLRelatory };
