import styled from 'styled-components';

export const Container = styled.header`
  height: 100%;
  box-shadow: 0 2px 16px 0px #00000010;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    margin-right: 50px;
  }

  .flex-mid {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px;
  }

  .icon-dashboard {
    display: flex;
    align-items: center;
  }

  .menu-icons {
    color: #7f8895;
    & > * {
      margin-left: 10px;
    }
    position: relative;

    .profile-submenu {
      position: absolute;
      padding: 0.6rem;
      right: 0;
      box-shadow: 4px 21px 15px 0px rgba(0, 0, 0, 0.1);
      z-index: 2;
      background: white;

      div {
        display: flex;
        justfify-content: space-between;
        cursor: pointer;
        &:hover {
          color: black;
        }
      }

      button {
        display: flex;
        justfify-content: space-between;
        flex: 1;
        align-items: center;
        cursor: pointer;
        &:hover {
          color: black;
        }
      }

      svg {
        font-size: 1rem;
        margin-right: auto;
      }

      p {
        text-align: right;
        font-size: 0.8rem;
        transition: color 0.2s ease;

        margin: 0.2rem 0;
      }
    }
  }

  .product-infos {
    border-left: 1px solid #939393;
    padding-left: 15px;
    text-align: center;
    color: #121e43;
    & > svg {
      margin-left: -5px;
    }
    .label-product {
      display: flex;
      align-items: center;
    }
  }

  .sing-out {
    border-left: 1px solid #939393;
    margin-left: 10px;
    padding-left: 15px;
    text-align: center;
    color: #121e43;
  }
`;
