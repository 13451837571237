import * as yup from 'yup';

export const enterpriseSchema = yup
  .object()
  .shape({
    enterpriseId: yup.string().nullable(),
    name: yup.string().min(1).required(),
    city: yup.string().required(),
    cep: yup.string().required(),
    price: yup
      .string()
      .transform((value) => value.replaceAll('.', '').replaceAll('R$ ', ''))
      .required(),
    link: yup.string(),
    photoLink: yup.string(),
    isPartnerDeveloper: yup.boolean(),
    isActive: yup.boolean(),
    address: yup.string().required(),
    addressNumber: yup.string().required(),
    district: yup.string().required(),
    portal: yup.string().required(),
  })
  .required();
