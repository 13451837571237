import { createGlobalStyle } from 'styled-components';
import { DefaultThemeType } from '../themes/theme';

const GlobalStyles = createGlobalStyle<DefaultThemeType>`
  //global.SC_DISABLE_SPEEDY = true
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&family=Lato:wght@300;400;700&display=swap');

  * {
    box-sizing: border-box;
  }

  body {
    background-color: #fff;
    font-family: 'Inter', sans-serif;
    color: #515151;
    h1, h2, h3, h4, h5, h6{
      font-family: 'Lato', sans-serif;
      color: #0A1937;
    }
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #aaa;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
  
  input, textarea{
    padding: 5px;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    background-color: #fff;
    transition: 0.2s ease-in-out;
    backface-visibility: hidden;
    outline: none;
    overflow: visible;
    letter-spacing: 1px;
  }

  button{
    border: none;
    cursor: pointer;
    font: inherit;
    background-color: inherit;
    color: inherit;
  }

  a{
    text-decoration: none;
    color: inherit;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  ol, ul {
    list-style: none;
  }

  .default-modal,
  .realtor-register-modal, 
  .register-password-modal {
    position: absolute;
    inset: 2rem 20vw;
    border: 1px solid rgb(204, 204, 204);
    background: white;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    z-index: 999;
  }
  
  .Overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 60%);
  }

  .realtor-register-modal {
    border-radius: 8px;
    inset: 12rem 28vw;
    padding: 1rem;

    .title-realtor-modal {
      font-size: 20px;
      font-weight: 900;
      text-align: center;
      padding-top: 1rem;
      padding-bottom: 2rem;
    }
  
    .content-realtor-modal {
      text-align: center;

      span {
        color: #515151;
        font-weight: 600;
      }
    }
  
    .button-realtor-modal {
      display: flex;
      justify-content: center;
      
      button {
        margin-top: 2rem;
        background: #002180;
        border-radius: 6px;
        color: #fff;
        font-weight: 600;
        padding: 0.5rem 2rem;
        width: max-content;
        transition-property: transform;

        &:hover {
          filter: brightness(1.30);
          border: 1px solid gray;
        }
      }
    }

  }

  .register-password-modal {
    display: flex;
    padding: 0.5rem;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border: none;
    inset: 8rem 38vw;
    overflow: hidden;

    img {
      height: 40px;
      margin-top: 3rem;
      margin-bottom: 2rem;
      width: 40px;
    }

    h1 {
      color: #000;
      font-size: 20px;
      margin-bottom: 2rem;
    }

    p {
      font-size: 13px;
      text-align: center;
      padding: 0 2rem; 
    }
  }

  .button-container {
    position: absolute;
    top: 18rem;
    background: #002180;
    text-align: center;
    color: #fff;
    padding: 1rem 0;
    font-weight: 600;
    width: 100%;
  }
  
  .circle {
    position: absolute;
    top: 7rem;
    left: -5rem;
    background: radial-gradient(234.41% 101.76% at 0% -1.76%, #0C70B8 0%, #2597C8 23.63%, #3CB6C7 44.92%, #2EE0CE 71.1%, #17E2C5 90.78%, #01E6B8 100%);
    opacity: 0.05;
    clip-path: circle(50% at 50% 50%);
    width: 23rem;
    height: 25rem;
    z-index: -1;
  }

  /* POPOVER Styles */
  
  .popover-title {
    color: #CED8E5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.6rem;
    font-family: Inter;
    font-weight: 400;
  }


  .rs-popover-content {
    padding: 0;


    hr {
      margin-top: 0.1rem;
      margin-bottom: 0.3rem;
    }
  }

  .action-button {
    .edit-button {
      display: flex;
      align-items: center;
      padding: 0;
      width: max-content;
    }

    svg {
      width: 16px;
      padding: 0;
      margin-right: 0.5rem;
    }
  }

  .thermometer-button{
    text-transform: capitalize;
    border-radius: 3px;
    font-size: 12px;
    padding: 0 .5rem;
    display: flex;
    align-items: center;

    &.quente {
      color: ${(p) => p.theme.colors.thermometer.hot};
      background: ${(p) => p.theme.colors.thermometer.hot + 30};
    }

    &.morno {
      color: ${(p) => p.theme.colors.thermometer.warm};
      background: ${(p) => p.theme.colors.thermometer.warm + 30};
    }

    &.frio {
      color: ${(p) => p.theme.colors.thermometer.cold};
      background: ${(p) => p.theme.colors.thermometer.cold + 30};
    }

  }


  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.react-modal-content {
  background: white;
  border-radius: 0.25rem;
  
  padding: 3rem;
  position: relative;
  width: 100%;
  max-width: 700px;
  z-index: 10000;
}
.react-modal-close {
  border: none;
  background: transparent;
  
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  transition: fitler .2s;
  &:hover {
      filter: brightness(0.7);
  }
}
  
  `;

export default GlobalStyles;
