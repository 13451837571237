/* eslint-disable no-useless-escape */
import {
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Button,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Box,
  Chip,
  Typography,
  Switch,
} from '@material-ui/core';
import { ButtonEnterprise, PageTitle } from 'components';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { withRouter } from 'react-router-dom';
import { JobRolesEnum } from 'utils/jobRolesEnum';
import { translateFeatures, translateJobRolesEnum } from 'utils/translateEnum';
import { useHistory, useParams } from 'react-router';
import { UserApi } from 'services/users';
import { IFeature, IUser } from 'services/users/type';

import { userSchema } from 'validations/user.schema';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { HubspotApi } from 'services/hubspot';
import { HubspotUser } from 'services/hubspot/types';
import { hubspotUserSchema } from 'validations/hubspotUserSchema';

import { Container } from './styles';
import { HubspotUserUpdateForm, managersData } from './form';

const HubspotUsersEditPage = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<HubspotUser>();

  const handleFindUser = async (userId: number) => {
    try {
      const { data } = await HubspotApi.findUser(userId);
      setUser({
        ...data,
        manager: managersData.find(
          (manager) => manager.activeTeamId === data.activeHubspotTeamId,
        )?.managerName,
      });
    } catch (error) {
      toast.error(`Nao foi possivel encontrar o usuario`);
    }
  };

  useEffect(() => {
    if (id) {
      handleFindUser(Number(id));
    }
  }, []);

  return (
    <Container>
      <PageTitle title="Usuarios do Hubspot">
        <button type="button" onClick={() => history.push('/hubspot/users')}>
          Voltar
        </button>
      </PageTitle>

      <Toaster />

      {user ? (
        <HubspotUserUpdateForm id={Number(id)} preloadData={user} />
      ) : (
        <>Carregando...</>
      )}
    </Container>
  );
};

export default withRouter(HubspotUsersEditPage);
