/* eslint-disable no-useless-escape */
import { MenuItem, TextField } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { MoreVert, EditAttributesOutlined } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import { ButtonEnterprise, Card, PageTitle } from 'components';
import { Select } from 'components/Select';
import { useQueryString } from 'hooks';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Popover, Whisper } from 'rsuite';
import { HubspotApi } from 'services/hubspot';
import { HubspotUser } from 'services/hubspot/types';
import { managersData } from 'pages/hubspot-users-create/form';
import { Container } from './styles';

const HubspotUsersPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [myFilters, setMyFilters] = useState({
    page: String(currentPage),
    limit: String(10),
    isActive: 'todos',
  });
  const history = useHistory();
  const filters = useQueryString(myFilters);
  const [users, setUsers] = useState<HubspotUser[]>([]);
  const [paginationInfo, setPaginationInfo] = useState({
    totalElements: 0,
    totalPages: 0,
    size: 0,
  });

  function getManagerFromHubspotActiveTeamId(activeTeamId: string | undefined) {
    if (!activeTeamId) return '-';

    const managerName = managersData.find(
      (manager) => manager.activeTeamId === activeTeamId,
    )?.managerName;

    if (!managerName) return '-';

    return managerName;
  }

  const onInputChange = (value: string, key: string) => {
    setMyFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const debouncedInputChangeHandler = useMemo(
    () => debounce(onInputChange, 300),
    [],
  );

  const handleFindAllHubspotUsers = async (qs?: string) => {
    try {
      const { data } = await HubspotApi.findUsers(qs);
      const { content, ...rest } = data;
      setUsers(content);
      setPaginationInfo({
        size: rest.size,
        totalElements: rest.totalElements,
        totalPages: rest.totalPages,
      });
    } catch (error) {
      toast.error('Houve um erro ao carregar a lista de usuários');
    }
  };

  useEffect(() => {
    handleFindAllHubspotUsers(filters);
  }, [filters]);

  return (
    <Container>
      <PageTitle title="Usuarios do Hubspot">
        <ButtonEnterprise
          onClick={async () => {
            await HubspotApi.syncUsers();
            await HubspotApi.findUsers(filters);
          }}
        >
          Sincronizar Com Usuarios do Hubspot
        </ButtonEnterprise>
      </PageTitle>

      <Toaster />

      <section className="filters">
        <Select
          name="manager"
          label="Gerente"
          onChange={(activeHubspotTeamId) =>
            setMyFilters((prevState) => ({
              ...prevState,
              activeHubspotTeamId,
            }))
          }
        >
          <MenuItem key="todos" value="todos">
            Todos
          </MenuItem>
          {managersData.map((manager) => (
            <MenuItem key={manager.activeTeamId} value={manager.activeTeamId}>
              {manager.managerName}
            </MenuItem>
          ))}
        </Select>
        <Select
          name="isOnRollete"
          label="Na Roleta"
          onChange={(isOnRollete) =>
            setMyFilters((prevState) => ({
              ...prevState,
              isOnRollete,
            }))
          }
        >
          <MenuItem key="todos" value="todos">
            Todos
          </MenuItem>
          <MenuItem key="ativo" value="true">
            Sim
          </MenuItem>
          <MenuItem key="inativo" value="false">
            Não
          </MenuItem>
        </Select>

        <Select
          name="isBroker"
          label="É Corretor"
          onChange={(isBroker) =>
            setMyFilters((prevState) => ({
              ...prevState,
              isBroker,
            }))
          }
        >
          <MenuItem key="todos" value="todos">
            Todos
          </MenuItem>
          <MenuItem key="ativo" value="true">
            Sim
          </MenuItem>
          <MenuItem key="inativo" value="false">
            Não
          </MenuItem>
        </Select>

        <Select
          name="isActiveOnHubspot"
          label="Ativo no Hubspot"
          onChange={(isActiveOnHubspot) =>
            setMyFilters((prevState) => ({
              ...prevState,
              isActiveOnHubspot,
            }))
          }
        >
          <MenuItem key="todos" value="todos">
            Todos
          </MenuItem>
          <MenuItem key="ativo" value="true">
            Sim
          </MenuItem>
          <MenuItem key="inativo" value="false">
            Não
          </MenuItem>
        </Select>

        <TextField
          className="filter-name"
          size="small"
          variant="outlined"
          label="Busca por nome"
          name="filter-name"
          defaultValue=""
          onChange={(e) => debouncedInputChangeHandler(e.target.value, 'name')}
        />
        <TextField
          className="filter-email"
          size="small"
          variant="outlined"
          label="Busca por e-mail"
          name="filter-email"
          defaultValue=""
          onChange={(e) => debouncedInputChangeHandler(e.target.value, 'email')}
        />
      </section>

      {users.length ? (
        <Card className="list">
          <section className="results">
            <span>Resultados: {users?.length ?? 0}/</span>
            {paginationInfo.totalElements}
          </section>
          <section className="columns-list">
            <p>ID</p>
            <p>NOME</p>
            <p>E-MAIL</p>
            <p>TELEFONE</p>
            {/* <p>GRUPO</p> */}
            <p>Gerente</p>
            <p />
            <p>ATIVO</p>
            <p>CORRETOR</p>
            <p>NA ROLETA</p>
            <p>-</p>
          </section>
          <section className="list-items">
            {users.length > 0 &&
              users.map((user) => (
                <section key={user.id} className="list-item">
                  <p>{user.id}</p>
                  <p>{user.name}</p>
                  <p>{user.email}</p>
                  <p>{user.phone ?? '-'}</p>
                  {/* <p>{user.group ?? '-'}</p> */}
                  <p>
                    {getManagerFromHubspotActiveTeamId(
                      user?.activeHubspotTeamId,
                    )}
                  </p>
                  <p />
                  <p>
                    <Switch checked={user.isActiveOnHubspot} disabled />
                  </p>
                  <p>
                    <Switch checked={user.isBroker} disabled />
                  </p>
                  <p>
                    <Switch checked={user.isOnRollete} disabled />
                  </p>

                  <p>
                    <Whisper
                      preventOverflow
                      enterable
                      trigger="click"
                      placement="left"
                      speaker={
                        <Popover
                          width="300px"
                          style={{
                            width: '240px',
                            zIndex: 4,
                          }}
                        >
                          <div
                            className="popover-title"
                            style={{
                              width: '100%',
                            }}
                          >
                            <p>AÇÃO</p>
                          </div>
                          <hr />
                          <div className="action-button">
                            <button
                              className="edit-button"
                              type="button"
                              onClick={() =>
                                history.push(`/hubspot/users/${user.id}`)
                              }
                            >
                              <EditAttributesOutlined />
                              Editar
                            </button>
                          </div>
                        </Popover>
                      }
                    >
                      <MoreVert className="dots" />
                    </Whisper>
                  </p>
                </section>
              ))}
          </section>
          <section className="footer-list">
            <Pagination
              count={paginationInfo.totalPages}
              size="small"
              className="pagination"
              onChange={(_, page) => {
                setCurrentPage(page);
                setMyFilters((prevState) => ({
                  ...prevState,
                  page: String(page),
                }));
              }}
            />
          </section>
        </Card>
      ) : (
        <p className="no-data">Sem registros disponíveis ou permitidos</p>
      )}
    </Container>
  );
};

export default withRouter(HubspotUsersPage);
