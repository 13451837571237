import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;

  .background-ilustrative {
    background-image: url('/ilustra-login.png');
    background-size: auto;
    background-position: bottom left;
    background-repeat: no-repeat;
    position: relative;
  }

  .logo {
    width: 168px;
    margin: 20px auto;
  }

  .form-container {
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto auto;

    p {
      margin-top: 10px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-top: 30px;
      .btn-form {
        padding: 18px;
        border-radius: 5px;
        color: white;
        background-color: ${({ theme }) => theme.colors.primaryColor};
        font-weight: 700;
      }
    }
  }

  .img {
    background-image: url('/img-login.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    height: 100%;
  }
`;
