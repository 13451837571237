import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

type WrapperProps = {
  isActive: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  cursor: pointer;
  box-shadow: ${({ isActive }) =>
    isActive
      ? `rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
      rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
      rgba(255, 255, 255, 0.08) 0px 1px 0px inset`
      : `0 4px 8px 0 rgba(0, 0, 0, 0.2)`};
  padding: 10px;
  border-radius: 10px;

  &:hover {
    transition: 0.3s;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
      rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
      rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  }
`;
