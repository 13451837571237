import React, { useEffect, useState } from 'react';
import { EnterprisesApi } from 'services/enterprises/index';
import {
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core';
import { Controller, useWatch } from 'react-hook-form';
import { ErrorLabel } from './ErrorsLabel';
import { PartnersContainer, Container } from './styles';

interface Props {
  register: any;
  index?: any;
  control?: any;
  portals?: any;
  enterpriseId: number;
}

const PortalFields: React.FC<Props> = ({
  register,
  index,
  control,
  portals,
  enterpriseId,
}) => {
  const announcementsWatcher = useWatch<any>({
    name: 'announcements',
    control,
  });
  const selectedPortal = announcementsWatcher[index]?.portal;
  const [isLoading, setIsLoading] = useState(false);
  const [validations, setValidations] = useState<any>({});

  const handleValidateEnterpriseAnnouncement = async (
    portal: string,
    id: number,
  ) => {
    try {
      setIsLoading(true);
      const { data } = await EnterprisesApi.validateAnnouncement({
        portal,
        enterpriseId: id,
      });

      setValidations({
        hasErrors: data.hasErrors,
        errors: data.errors.split('\n').filter(Boolean),
      });
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedPortal) {
      return;
    }
    handleValidateEnterpriseAnnouncement(selectedPortal, enterpriseId);
  }, []);

  const portalsToDisableIDField = ['Apto.vc', 'AoCubo', 'AppMeuImovel'];

  return (
    <Container isLoading={isLoading}>
      <PartnersContainer>
        <Controller
          control={control}
          name={`announcements.${index}.portal`}
          render={({ field }) => (
            <TextField
              select
              size="small"
              variant="outlined"
              margin="normal"
              label="Portal"
              {...field}
            >
              {portals.map(({ value, label }: any) => (
                <MenuItem
                  key={value}
                  value={value}
                  onClick={() =>
                    handleValidateEnterpriseAnnouncement(label, enterpriseId)
                  }
                >
                  {label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <TextField
          size="small"
          variant="outlined"
          margin="normal"
          label="External ID"
          {...register(`announcements.${index}.integrationId`)}
          disabled={portalsToDisableIDField.includes(selectedPortal)}
        />

        <TextField
          size="small"
          variant="outlined"
          margin="normal"
          label="Link do anúncio"
          {...register(`announcements.${index}.announcementUrl`)}
        />

        <FormControlLabel
          label="Inativo / Ativo"
          control={
            <Controller
              name={`announcements.${index}.isActive`}
              control={control}
              render={({ field: { onChange, value, name, ref } }) => (
                <Switch
                  checked={value}
                  name={name}
                  inputRef={ref}
                  onChange={onChange}
                  disabled={validations.hasErrors}
                />
              )}
            />
          }
        />
      </PartnersContainer>
      {validations.hasErrors &&
        validations?.errors.map((error: string) => (
          <ErrorLabel error={error} key={error} />
        ))}
    </Container>
  );
};

export default PortalFields;
