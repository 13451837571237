import * as yup from 'yup';

export const userSchema = yup
  .object()
  .shape({
    name: yup.string().min(1).required(),
    email: yup.string().email().required(),
    // featuresIds: yup.array().of(yup.string()).required(),
    jobRole: yup.string().required(),
    isActive: yup.boolean().default(true),
  })
  .required();
