import styled from 'styled-components';
import 'typeface-inter';

export default styled.section`
  font-family: Inter;
  background: #002180;
  color: white;
  padding: 1rem 0.5rem;

  > button {
    padding: 0 1rem;
  }

  > ul {
    &.active li {
      a {
        grid-template-columns: 1fr;
      }
      span {
        display: none;
      }
    }

    li a {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 1.5rem;
      padding: 0.6rem 1rem;
      margin: 1rem 0;
      align-items: center;
      font-weight: 600;

      :hover {
        background: #ced8e5;
        color: #121e43;
        cursor: pointer;
        border-radius: 5px;
      }

      svg {
      }
      span {
        font-size: 14px;
      }
    }
  }
`;
