/* eslint react/prop-types: 0 */
import React from 'react';
import Container from './styles';

interface PageProps {
  title: string;
  buttonAction?: () => void;
}

const PageTitle: React.FC<PageProps> = ({ title, children }) => {
  return (
    <Container>
      <div>
        <h1>{title}</h1>
        {children}
      </div>
      <section className="lines">
        <hr className="blue" />
        <hr />
      </section>
    </Container>
  );
};

PageTitle.defaultProps = {
  buttonAction: undefined,
};

export { PageTitle };
