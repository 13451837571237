const defaultColors = {
  text: '#515151',
  textTitle: '#0A1937',
  primaryColor: '#002180',
  secondaryColor: '#00E6B8',
  thirdColor: '#106BF3',
  grey: '#7F8895',
  darkBlue: '#121E43',
  dangerColor: '#DB2D2D',
  thermometer: {
    hot: '#FF5454',
    warm: '#FC7900',
    cold: '#007DFF',
  },
};

export const DefaultTheme = {
  colors: defaultColors,
};

export type DefaultThemeType = {
  theme: typeof DefaultTheme;
};

export default DefaultTheme;
