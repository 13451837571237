import axios from 'axios';
import { IPaginatedResponse } from '../../typings/pagiant';
import { HubspotUser } from './types';

const findUsers = (query?: string) =>
  axios.get<IPaginatedResponse<HubspotUser>>(`/hubspot/users/?${query}`);

const findUser = (id: number) => axios.get<HubspotUser>(`/hubspot/users/${id}`);

const syncUsers = () => axios.post('/hubspot/create-users');

const update = (id: number, payload: Partial<HubspotUser>) =>
  axios.put(`/hubspot/users/${id}`, payload);

const getActiveOfferLead = () => axios.post('/api/v1/crm/active-offer');
const findAcitveOfferLeads = (querySring?: string) =>
  axios.get(`/api/v1/crm/active-offer?${querySring}`);

const sendActiveOfferToUserPipeLine = (id: number) =>
  axios.patch(`/api/v1/crm/active-offer/${id}`);

const rejectActiveOfferLead = (payload: any) =>
  axios.patch(`/api/v1/crm/active-offer/reject/${payload.id}`, payload);
export const HubspotApi = {
  findUsers,
  findUser,
  update,
  syncUsers,
  getActiveOfferLead,
  findAcitveOfferLeads,
  sendActiveOfferToUserPipeLine,
  rejectActiveOfferLead,
};
