import React from 'react';
import { TextField } from '@material-ui/core';
import IMask from 'imask';

import { InputProps } from 'typings/input';

const Input = ({
  className,
  disabled,
  defaultValue,
  error,
  label,
  name,
  onChange,
  onBlur,
  value,
  type,
  inputRef,
  mask,
  maxLength,
}: InputProps) => {
  const masksDictionary: any = {
    cellphone: '(00) 00000-0000',
    cpf: '000.000.000-00',
    date: '00/00/0000',
    cnpj: '00.000.000/0000-00',
    postalcode: '00000-000',
    phone: '(00) 0000-0000',
  };

  function handlePhoneMask(maskToApply: string, target: HTMLInputElement) {
    if (maskToApply && target.value.length > 14) {
      IMask(target, { mask: masksDictionary.cellphone });
    } else {
      IMask(target, { mask: masksDictionary.phone });
    }
  }

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (onChange) onChange(event);

    if (mask) {
      if (mask === 'phone') {
        handlePhoneMask(mask, event.target as HTMLInputElement);
      }
      IMask(event.target, { mask: masksDictionary[mask] });
    }
  }

  return (
    <TextField
      className={className}
      defaultValue={defaultValue}
      disabled={disabled}
      label={label || ''}
      error={!!error}
      name={name}
      onChange={() => handleOnChange}
      onBlur={onBlur}
      type={type || 'text'}
      inputProps={{ maxLength }}
      value={value}
      style={{ width: '100%' }}
      inputRef={inputRef}
      variant="outlined"
    />
  );
};

export { Input };
