import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { ButtonEnterprise, InputCurrency } from 'components';
import {
  FormControlLabel,
  FormGroup,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useHistory } from 'react-router';
import toast from 'react-hot-toast';
import { newEnterpriseSchema } from 'validations/newEnterprise.schema';
import {
  FormContainer,
  AddFields,
  ButtonContainer,
  EnterpriseId,
  LinkTo,
  Box,
} from './styles';
import PortalFields from '../PortalFields';
import { portals } from '../constants';

type AnnouncementsType = {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null | string;
  portal: string;
  integrationId: string;
  isActive: boolean;
  announcementUrl: string;
  photoUrl: string;
};

type EnterpriseType = {
  id: number;
  type: string; // caso sejam PROPERTY o propertyIntegrationId será nullo
  integrationId: number; // ID DA UNIT
  propertyIntegrationId: number; // ID DO PAI
  developer: { name: string };
  isActive: boolean;
  name: string;
  price: number;
  address: {
    city: string;
    region: string;
  };
  announcements: AnnouncementsType[];
};

interface FormProps {
  enterpriseData: any;
  zones: { value: string; label: string }[];
}

const EnterpriseForm = ({ enterpriseData, zones }: FormProps) => {
  const history = useHistory();

  const { register, control, handleSubmit, reset, getValues } = useForm({
    defaultValues: enterpriseData,
    resolver: yupResolver(newEnterpriseSchema),
  });

  const { fields, append } = useFieldArray({
    control,
    name: 'announcements',
  });

  const priceWatcher = useWatch<any>({
    name: 'price',
    control,
    defaultValue: 'watching price field',
  });

  useEffect(() => {
    String(priceWatcher).replace(',', '');
  }, []);

  const onSubmit = (data: EnterpriseType) => {
    axios
      .put(`/api/v1/crm/enterprises/${data.id}`, {
        ...enterpriseData,
        ...data,
        announcements: data.announcements,
        isActive: data.isActive,
        region: data.address.region,
      })
      .then(() => {
        history.push('/newEnterprises');
        toast.success('Empreendimento atualizado com sucesso!');
      });
  };

  useEffect(() => {
    reset(enterpriseData);
  }, [enterpriseData]);

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormGroup
        row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <FormControlLabel
          control={
            <Controller
              name="isActive"
              control={control}
              render={({ field: { value } }) => (
                <Switch checked={value} disabled />
              )}
            />
          }
          label="Empreendimento Ativo"
        />
      </FormGroup>

      <EnterpriseId>ID: {enterpriseData.id}</EnterpriseId>

      <Box>
        <TextField
          label="Nome do empreendimento"
          size="small"
          variant="outlined"
          margin="normal"
          {...register('name')}
          disabled
        />{' '}
        <LinkTo
          href={`https://admin-corretor.aocubo.com/imoveis/editar/${enterpriseData.propertyIntegrationId}`}
          target="_blank"
        >
          Ver no admin
        </LinkTo>
        {enterpriseData.type === 'PROPERTY_UNIT' && (
          <LinkTo
            href={`https://admin-corretor.aocubo.com/imoveis/${enterpriseData.propertyIntegrationId}/unidades/editar/${enterpriseData.integrationId}`}
            target="_blank"
          >
            Ver unidade
          </LinkTo>
        )}
      </Box>
      <TextField
        label="Incorporadora"
        value="AoCubo"
        size="small"
        variant="outlined"
        margin="normal"
        {...register('developer.name')}
        InputLabelProps={{
          shrink: true,
        }}
        disabled
      />
      <TextField
        size="small"
        variant="outlined"
        margin="normal"
        label="Preço"
        {...register('price')}
        InputProps={{
          inputComponent: InputCurrency,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        disabled
      />
      <Controller
        control={control}
        name="address.region"
        render={({ field }) => (
          <TextField
            select
            size="small"
            variant="outlined"
            margin="normal"
            label="Zona"
            required
            {...field}
          >
            {zones.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        )}
      />

      {enterpriseData.type === 'PROPERTY' && (
        <Controller
          control={control}
          name="careTeam"
          render={({ field }) => (
            <TextField
              select
              size="small"
              variant="outlined"
              margin="normal"
              label="Equipe de Atendimento"
              required
              {...field}
            >
              <MenuItem value="IBroker">IBroker</MenuItem>
              <MenuItem value="Novos Negocios">Novos Negócios</MenuItem>
            </TextField>
          )}
        />
      )}

      <AddFields
        type="button"
        onClick={() => {
          append({ values: enterpriseData });
        }}
        // disabled={fields.length === portals.length}
      >
        Adicionar Anúncios
      </AddFields>

      {fields.map((currentField, index) => (
        <PortalFields
          register={register}
          index={index}
          key={currentField.id}
          control={control}
          portals={portals}
          enterpriseId={enterpriseData.id}
        />
      ))}

      <ButtonContainer>
        <ButtonEnterprise type="submit">Enviar</ButtonEnterprise>
      </ButtonContainer>
    </FormContainer>
  );
};

export default EnterpriseForm;
