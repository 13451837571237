import React from 'react';
import { toLocateDate } from 'utils/format';
import { IXMLRelatory } from '../../../services/xmlRelatory';

type Props = {
  XMLRelatory: IXMLRelatory;
  title: string;
};

export const CardContent: React.FC<Props> = ({ XMLRelatory, title }) => (
  <>
    <p style={{ textAlign: 'center', margin: '8 0' }}>{title}</p>
    <p>Total de Anúncios: {XMLRelatory?.totalAnnouncements}</p>
    <p>Anúncios Enviados: {XMLRelatory?.totalSent}</p>
    <p>Anúncios Incompletos: {XMLRelatory?.lackInfo}</p>
    <p>Anúncios Economicos: {XMLRelatory?.economic}</p>
    <p>Anúncios Médio/Alto Padrão: {XMLRelatory?.highStandard}</p>
    <p>Arquivo: {XMLRelatory?.filename}</p>
    <p>Gerado em: {toLocateDate(XMLRelatory?.createdAt)}</p>
  </>
);
