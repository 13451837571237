import axios from 'axios';
import { IUserProfile } from './types';

async function index() {
  return axios.get<IUserProfile>(`/profile`);
}

const changePassword = (payload: any) => axios.put(`/profile/reset`, payload);

export const ProfileApi = {
  index,
  changePassword,
};
