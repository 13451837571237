import * as yup from 'yup';

export const hubspotUserSchema = yup
  .object()
  .shape({
    name: yup.string().min(1).required(),
    email: yup.string().email().required(),
    phone: yup
      .string()
      .nullable()
      .transform((value) => value?.replace('+55', ''))
      .transform((value) => value?.replace(/[^0-9]/g, ''))
      .test({
        message: 'O telefone deve conter 11 caracteres, exemplo: 11912345678',
        test(value) {
          if (value && value?.length !== 11) return false;

          return true;
        },
      }),
    // featuresIds: yup.array().of(yup.string()).required(),
    isActiveOnHubspot: yup.boolean(),
    isOnRollete: yup.boolean().default(false),
    isBroker: yup.boolean().default(false),
    manager: yup
      .string()
      .nullable()
      .test({
        message: 'Gerente é obrigatório para corretores',
        test(value) {
          const { isBroker } = this.parent;

          if (isBroker && !value) return false;

          return true;
        },
      }),
  })
  .required();
