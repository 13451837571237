import axios from 'axios';

const findAllEnterprises = async (queryString?: string) => {
  return axios.get(`/enterprise?${queryString ?? ''}`);
};

const findEnterprise = async (id: number) => {
  return axios.get(`/enterprise/${id}`);
};

const createEnterprise = async (enterprise: any) => {
  return axios.post('/enterprise', enterprise);
};

const updateEnterprise = async (enterprise: any) => {
  return axios.put(`/enterprise/${enterprise.id}`, enterprise);
};

const getEnterpriseAptoCSV = async () => {
  return axios.get(`/enterprise/aptovc/csv`, { responseType: 'blob' });
};

const getEnterpriseAdminRealtorCsv = async (start: string, end: string) => {
  return axios.post(
    `/api/v1/crm/enterprises/relatory-audit`,
    {
      start,
      end,
    },
    {
      responseType: 'blob',
    },
  );
};

export {
  findAllEnterprises,
  updateEnterprise,
  createEnterprise,
  findEnterprise,
  getEnterpriseAptoCSV,
  getEnterpriseAdminRealtorCsv,
};
