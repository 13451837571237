/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from '@hookform/resolvers/yup';

import { useEnterpriseContext } from 'contexts/Enterprises';
import React from 'react';
import { useForm } from 'react-hook-form';
import ReactModal from 'react-modal';
import { enterpriseSchema } from 'validations/enterprise.schema';
import { EnterpriseForm } from '../form';

type Props = {
  isModalOpen: boolean;
  onCloseModal: () => void;
};

const NewEnterpriseModal: React.FC<Props> = ({ isModalOpen, onCloseModal }) => {
  const { reset } = useForm({
    resolver: yupResolver(enterpriseSchema),
  });
  const { handleCreateEnterprise, enterpriseCopy } = useEnterpriseContext();

  const onSubmit = async (data: any) => {
    await handleCreateEnterprise(data);
    reset();
    onCloseModal();
  };

  const defaultValues = enterpriseCopy
    ? {
        ...enterpriseCopy,
        enterpriseId: null,
      }
    : null;

  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={onCloseModal}
      appElement={document.getElementById('root') || undefined}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      style={{
        content: {
          overflow: 'scroll',
          height: '100%',
        },
      }}
    >
      <div>
        <h1>Criar novo empreendimento</h1>
        <EnterpriseForm
          defaultValues={defaultValues}
          onFormSubmit={onSubmit}
          buttonTitle={
            defaultValues ? 'Duplicar Empreendimento' : 'Criar Empreendimento'
          }
        />
      </div>
    </ReactModal>
  );
};

NewEnterpriseModal.defaultProps = {
  isModalOpen: undefined,
  onCloseModal: undefined,
};

export { NewEnterpriseModal };
