/* eslint-disable no-useless-escape */
import { ButtonEnterprise, Card, PageTitle } from 'components';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { findAllEnterprises, getEnterpriseAptoCSV } from 'services/enterprises';
import Container from './styles';

const EnterpriseAptoCSVPage = ({ match }: RouteComponentProps) => {
  const [enterprisesCount, setEnterprisesCount] = useState(0);

  const handleDownloadAptoCSV = async () => {
    try {
      const { data } = await getEnterpriseAptoCSV();

      const blob = new Blob([data], { type: 'application/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${Date.now()}-aptovc.csv`;

      link.click();
    } catch (error) {
      toast.error('Falha ao baixar CSV');
    }
  };

  useEffect(() => {
    async function countEnterprises() {
      const {
        data: { total },
      } = await findAllEnterprises(`portal=Apto.vc`);

      setEnterprisesCount(total);
    }

    countEnterprises();
  }, []);

  return (
    <Container>
      <Toaster />
      <PageTitle title="Relatorio em CSV dos Empreendimentos Apto.vc" />

      <Card>
        <div>
          <h4>Quantidade de Empreendimentos: {enterprisesCount}</h4>
          <ButtonEnterprise
            type="button"
            onClick={() => handleDownloadAptoCSV()}
          >
            Baixar CSV
          </ButtonEnterprise>
        </div>
      </Card>
    </Container>
  );
};

export default withRouter(EnterpriseAptoCSVPage);
