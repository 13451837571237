/* eslint-disable react/prop-types */
import { TextField } from '@material-ui/core';
import React from 'react';

type Props = {
  onChange: (value: string) => void;
  name: string;
  label: string;
  defaultValue?: string;
};

const Select: React.FC<Props> = ({
  onChange,
  name,
  children,
  label,
  defaultValue = 'todos',
}) => {
  return (
    <TextField
      select
      size="small"
      variant="outlined"
      className="filter-status"
      label={label}
      name={name}
      defaultValue={defaultValue}
      onChange={(e) =>
        onChange(e.currentTarget.getAttribute('data-value') || '')
      }
    >
      {children}
    </TextField>
  );
};

export { Select };
