/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from '@hookform/resolvers/yup';
import { MenuItem, TextField } from '@material-ui/core';
import {
  AutoCompleteEnterprises,
  ButtonEnterprise,
  ConfirmModal,
} from 'components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import ReactModal from 'react-modal';
import { createLead } from 'services/leadsHubspot';
import { leadSchema } from 'validations/lead.schema';

type Props = {
  isModalOpen: boolean;
  onCloseModal: () => void;
};

const portals = [
  {
    value: '123i',
    label: '123i',
  },
  {
    value: 'Apto.vc',
    label: 'Apto.vc',
  },
];

const LeadModal: React.FC<Props> = ({ isModalOpen, onCloseModal }) => {
  const { register, handleSubmit, reset, watch, setValue } = useForm({
    resolver: yupResolver(leadSchema),
  });
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [payload, setPayload] = useState({});
  const [portal, setPortal] = useState<string>();

  const onSubmit = async (data: any) => {
    setPayload({
      ...data,
      code: 'AoCuboCRM',
    });
    setIsOpenConfirmModal(true);
  };

  const confirmSubmit = async () => {
    try {
      await createLead(payload);
      toast.success('Lead criado com sucesso!');
    } catch (error) {
      console.log(error);
      toast.error('Erro ao criar lead!');
    } finally {
      setIsOpenConfirmModal(false);
      setPayload({});
      reset();
      onCloseModal();
    }
  };

  const cancelSubmit = () => {
    onCloseModal();
    setPayload({});
  };

  const handleSelectPortal = (value: string) => {
    switch (value) {
      case 'Apto.vc':
        return 'Apto.vc';
      default:
        return 'AoCubo';
    }
  };

  const handleSelectKeyByPortal = (value: string) => {
    switch (value) {
      case 'Apto.vc':
        return 'enterpriseId';
      default:
        return 'name';
    }
  };

  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={cancelSubmit}
      appElement={document.getElementById('root') || undefined}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
    >
      <ConfirmModal
        title="Confirmar Criação de Lead"
        isModalOpen={isOpenConfirmModal}
        onCloseModal={() => setIsOpenConfirmModal(false)}
        onConfirm={() => confirmSubmit()}
      >
        <p>Não sera possivel editar os dados uma vez criado.</p>
      </ConfirmModal>
      <div>
        <h1>Criar novo lead</h1>
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            size="small"
            variant="outlined"
            margin="normal"
            label="Nome do Lead"
            {...register('name')}
          />

          <TextField
            size="small"
            variant="outlined"
            margin="normal"
            label="Email"
            {...register('email')}
          />
          <TextField
            size="small"
            variant="outlined"
            margin="normal"
            label="Telefone"
            {...register('phone')}
          />
          <TextField
            select
            size="small"
            variant="outlined"
            margin="normal"
            label="Portal"
            value={watch('origin')}
            {...register('origin')}
            onChange={({ target: { value } }: any) => {
              setPortal(handleSelectPortal(value));
            }}
          >
            {portals.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
          {portal && (
            <AutoCompleteEnterprises
              onSelect={(enterprise) => setValue('enterprise', enterprise)}
              portal={portal}
              portalKey={handleSelectKeyByPortal(portal)}
            />
          )}

          <ButtonEnterprise type="submit">Cadastrar Lead</ButtonEnterprise>
        </form>
      </div>
    </ReactModal>
  );
};

LeadModal.defaultProps = {
  isModalOpen: undefined,
  onCloseModal: undefined,
};

export { LeadModal };
