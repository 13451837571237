import styled from 'styled-components';
import 'typeface-inter';

const Container = styled.section`
  margin: 0 1rem;
  p.no-data {
    color: #999;
    text-align: center;
    margin: 5rem 0;
  }
  nav {
    padding: 0;
  }
  .button-register {
    display: flex;
    align-items: center;
    color: #002180;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: max-content;
    p {
      display: flex;
      justify-content: center;
      background-color: #002180;
      border-radius: 50%;
      display: flex;
      margin-right: 1rem;
      width: 1.5rem;
      svg {
        color: #fff;
        width: 1rem;
      }
    }
  }
  .url {
    color: #002180;
  }
  .dots {
    color: #c5cdda;
    cursor: pointer;
  }
  .results {
    padding-left: 2.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 13px;
    span {
      color: #0a1937;
      font-weight: 600;
    }
  }
  .list {
    box-shadow: 5px 0px 15px 5px rgba(70, 68, 83, 0.1);
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
    padding: 0;
    width: 100%;
    .columns-list {
      display: grid;
      grid-template-columns: 15% 15% 10% 18% 15% 15%;
      grid-gap: 1rem;
      font-size: 12px;
      font-weight: 600;
      color: #121e43;
      background: #f3f7fb;
      padding: 0.5rem 0 0.3rem 1rem;
      width: 100%;
    }
    .list-items {
      background: #f3f7fb;
      &:nth-child(6) {
        text-overflow: ellipsis;
        width: 2rem;
        background: red;
      }
    }
    p {
      &:first-child {
        text-align: center;
        color: #0a1937;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .list-item {
    display: grid;
    grid-template-columns: 16% 15% 10% 18% 15% 15%;
    grid-gap: 1rem;
    align-items: center;
    background: white;
    margin: 3px 0;
    font-size: 14px;
    padding: 0.5rem 0;
  }
  .list-item:hover {
    background-color: #edf5fa;
  }
  .footer-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem 1.2rem 0.5rem 0;
    p {
      font-size: 13px;
      margin-right: 2rem;
    }
    span {
      color: #0a1937;
      font-weight: 600;
    }
  }
  .filters {
    display: grid;
    grid-template-columns: 10% 15% 15% 15% 13% 15% auto;
    gap: 5px;
    margin: 0.5rem 0;
  }
`;

export default Container;
