export const useQueryString = (initialState: any) => {
  const arrayFilters = Object.entries<string>(initialState).filter(
    (item) => !item.includes('todos') && item[1].trim().length > 0,
  );

  const finalFilterObject = Object.fromEntries<string>(arrayFilters);

  const queryString = new URLSearchParams(finalFilterObject).toString();

  return queryString;
};
